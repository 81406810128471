.accordion {
  background-color: transparent;
  box-shadow: none;
  padding-top: 16px;
  margin-bottom: 6px;
}

.accordion > :global(.Mui-expanded) {
  border-bottom: none;
}

.accordion:before {
  display: none;
}

.accordionSummary {
  margin-left: 2px;
  display: flex;
  align-items: center;
  direction: ltr;
  min-height: 20px !important;
  margin-top: -16px;
  padding-bottom: 8px !important;
}

.accordionSummary > div:first-child {
  margin-left: -12px !important;
}

@media only screen and (max-device-width: 480px) {
  .accordionSummary {
    margin-top: -12px;
  }
}

.accordionSummary > div {
  align-items: center;
  margin: 0 !important;
}

.title {
  font-size: 16px;
  line-height: 20px;
  color: var(--gray_label);
  margin-left: 14px;
}

.line {
  border-top: 1px solid var(--gray_border);
  width: 100%;
  flex: 1;
}

.accordionDetails {
  padding: 0;
  position: relative;
  height: 440px;
}
