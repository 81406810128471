.button {
  color: var(--white);
  background-color: var(--blue);
  padding: 6px 16px;
  box-shadow: var(--btn_shadow);
  transition: 0.2s ease !important;
}

.button:hover {
  background-color: darken(#2196f3, 10%);
}

.mainButton {
  @extend .button;
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.mainButton :global(.MuiButton-label) {
  display: flex;
  gap: 0 8px;
}

.mobileTryAlgo {
  margin: 12px;
}

.textButton {
  color: var(--gray_text);
}

.textButton > span {
  font-size: var(--text_3) !important;
  text-transform: none;
  padding-left: 10px;
}

.tryProgress {
  margin: 0 20px 0 auto;
  padding: 8px 24px;
}
