.container {
  display: flex;
  flex-direction: column;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 24px;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray_border);
}

.brokerInput {
  width: 230px;
  margin-right: 24px;
}

.brokerInput :global(.MuiSelect-root) {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 24px;
}

.brokerInput > p {
  position: absolute;
  bottom: -23px;
}

.brokerOption {
  min-width: 300px;
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.name {
  font-size: var(--text_4);
}

.infoMessage {
  display: flex;
  align-items: center;
}

.infoMessageText {
  font: var(--text_5_up_m);
  text-transform: uppercase;
  color: var(--gray_text);
  padding-left: 11px;
}

.switchEngineRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.notifications {
  width: 100%;
  margin-top: 24px;
}

.selectFieldRow {
  display: flex;
  align-items: center;
}

.brokersToggle {
  margin-right: 16px;
  text-transform: uppercase;
}

.row {
  min-height: 88px;
  padding: 24px 0;
  border-bottom: 1px solid var(--gray_border);
}

.row:last-child {
  border-bottom: none;
}

.rowLine {
  display: flex;
  align-items: center;
  padding: 0 36px;
}

.message {
  margin-top: 16px;
  padding: 24px 24px 16px;
  border-radius: 4px;
}

.agreement {
  composes: message;
  background-color: var(--blue_dim);
  border: 1px solid var(--blue);
}

.errorMessage {
  composes: message;
  background-color: var(--red_dim);
  border: 1px solid var(--red);
}

.messageShow {
  display: block;
}

.messageHide {
  display: none;
}

.linkIcon {
  margin-right: 8px;
  height: 18px;
  width: 18px;
}

.linkButton {
  color: var(--blue);
  margin-left: -34px;
}

.buttonWrapper {
  min-width: 150px;
}

.agreementButtons {
  margin-top: 8px;
  margin-left: -8px;
}

.agreeButton {
  color: var(--blue);
}

.disclaimer {
  color: var(--gray_label);
}

.disclaimer > div {
  padding-top: 16px;
  line-height: 20px;
}

.linkIcon {
  margin-right: 8px;
}

.linkStatusTemplate {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.linkStatusOK {
  composes: linkStatusTemplate;
  color: var(--green);
}

.linkStatusError {
  composes: linkStatusTemplate;
  color: var(--red);
}

.comingSoonButton {
  padding-left: 0;
}
