.input {
  margin-top: 8px;
  margin-bottom: 19px;
}

.input [class*="MuiFormHelperText"] {
  position: absolute;
  bottom: -22px;
}

.input div[class*="chipContainer"] {
  margin-top: 2px;
  margin-bottom: 2px;
}

.input div[class*="MuiChip"] {
  height: 26px;
}

.icon {
  height: 16px;
  position: relative;
  top: 2px;
  color: var(--blue);
}

.option {
  color: var(--blue);
  width: 100%;
}

.plainoption {
  padding-left: 5px;
  width: 100%;
}

/* clears the 'X' from Internet Explorer */
.inputSearch input[type="search"]::-ms-clear,
.inputSearch input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
.inputSearch input[type="search"]::-webkit-search-decoration,
.inputSearch input[type="search"]::-webkit-search-cancel-button,
.inputSearch input[type="search"]::-webkit-search-results-button,
.inputSearch input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
