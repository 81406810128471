.container {
  background-color: var(--white);
  border: 1px solid var(--gray_border);
  position: relative;
  flex-grow: 1;
}

.container:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-left: none;
}

.container:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: none;
  margin-left: -1px;
}

.container.readOnly {
  padding-bottom: 8px;
  padding-left: 2px;
  border-top: none;
}

.container.error {
  border: none;
  position: relative;
}

.container :global(.ace_error) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="001616" fill="none" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="featherfeather-alert-circle"><circle cx="8" cy="8" r="6"></circle><line x1="8" y1="5" x2="8" y2="9"></line><line x1="8" y1="11" x2="8" y2="11"></line></svg>');
  background-color: var(--red);
  color: var(--white);
}

/* default ace annotations tooltip */
.container :global(.ace_tooltip) {
  padding: 8px 16px;
  border: 1px solid var(--red);
  background: var(--white);
  box-shadow: none;
  border-radius: 4px;
  font: var(--text_code);
  white-space: normal;
}

/* number of errors text. hiding, as it's always just one. */
.container :global(.ace_tooltip .ace_gutter-tooltip_header) {
  display: none;
}

.container :global(.ace_tooltip .ace_error) {
  display: none;
}

.container :global(.ace_tooltip .ace_gutter-tooltip_body) {
    padding: 0;
}

.container.mobile {
  border-right: 0;
  border-left: 0;
}

.label {
  display: flex;
  align-items: center;
  height: 24px;
  background: white;
}

.label.readOnly {
  height: auto;
  padding: 8px 11px;
}

.left {
  width: 40px;
  height: 100%;
  padding-right: 13px;
  box-sizing: border-box;
  text-align: end;
  background-color: var(--back_light);
}

.right {
  font: var(--text_5_up_m);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--gray_icon);
  padding-left: 5px;
}

.info {
  height: 14px;
  top: 3px;
  position: relative;
}

.errorLabel {
  position: absolute;
  background: var(--red_dim);
  color: var(--red);
  bottom: 0;
  padding-left: 48px;
  width: 100%;
  z-index: 4;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
}

.errorBorder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  pointer-events: none;
  border: 1px solid red;
}

.errorBorderLeft {
  width: calc(100% + 1px);
}

.errorBorderRight {
  left: -1px;
  width: calc(100% + 1px);
}

.readOnly :global(.ace_gutter),
.readOnly :global(.ace_active-line),
.readOnly :global(.ace_cursor) {
  display: none !important;
}

.readOnly :global(.ace_scroller) {
  left: 0 !important;
  cursor: initial;
  box-shadow: none !important;
}

.readOnly :global(.ace_scrollbar-h) {
  left: 0 !important;
}

.readOnly :global(.ace_editor) {
  width: auto !important;
  margin-left: 12px;
  margin-right: 12px;
}

.errorMarker {
  position: absolute;
  background-color: var(--red_dim);
  pointer-events: auto;
  right: 0;
  width: unset !important;
  border-radius: unset;
}

.readOnly .errorMarker {
  background-color: transparent;
}

.editor {
  min-height: calc(100% - 24px);
}
