.list {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: none;
}

.list td:first-child {
  padding-left: 4px;
}

.list > :global(.MuiToolbar-regular) {
  max-height: none;
  padding-left: 0 !important;
}

.list :global(.MuiIconButton-root) {
  color: var(--gray_icon);
}

.datagrid th {
  height: 52px;
  padding: 0;
}

.datagrid td {
  height: 49px;
  padding: 0;
}

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  margin-top: 0;
  height: 61px;
  max-height: 61px;
  min-height: 61px;
}

.filter > div[class^="RaFilterForm-clearFix"] {
  display: none;
}

.filter > :global(.filter-field) {
  padding: 0;
}

.filter :global(.MuiFormControl-marginDense) {
  margin: 0;
}

.filter :global(.MuiInputBase-inputMarginDense) {
  padding-top: 6px;
}

.filter :global(.MuiFormHelperText-marginDense) {
  margin-top: 0;
}

.selectStatusInput {
  min-width: 100px !important;
}

.selectStatusInput label.MuiFormLabel-filled,
.selectStatusInput label.Mui-focused {
  display: none;
}

.selectStatusInput :global(.MuiChip-root) {
  height: 20px !important;
}

.selectStatusInput :global(.MuiSelect-select) {
  padding-bottom: 4px;
}

order_row {
  padding-bottom: 6px;
  padding-top: 6px;
}

.value {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--black);
  padding: 4px 0;
  display: flex;
  align-items: center;
}

.unsuccessful {
  color: var(--gray_icon);
  width: 18px;
  height: 18px;
  position: relative;
  top: 4px;
}

.firstRow {
  border-top: 1px solid var(--gray_border);
  padding-top: 16px;
}

.lastRow {
  padding-bottom: 16px;
}

.closedOrdersData {
  padding-right: 16px;
}

.gainLossTicker {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  border-top: 1px solid var(--gray_border);
}

.gainLoss {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 4px;
  border-radius: 2px;
}

.gain {
  color: var(--green);
  background: var(--green_dim);
}

.loss {
  color: var(--red);
  background: var(--red_dim);
}

.ticker {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 4px;
  color: var(--gray_text);
  background: var(--back_light);
  border-radius: 2px;
  margin-top: 8px;
}

.date_col {
  width: 20vw;
}

.trades_col {
  max-width: 5vw;
  min-width: 5vw;
  width: 5vw;
  text-align: center !important;
}

.mobile_trades_col {
  width: 3vw;
  max-width: 3vw;
  padding: 0 0 0 10px !important;
}

.gain_col {
  max-width: 20vw;
  min-width: 10vw;
  width: 10vw;
  padding-right: 3vw !important;
  white-space: nowrap;
  text-align: right !important;
}

.header {
  text-align: center !important;
}
