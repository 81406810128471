.container {
  margin-left: auto;
  padding: 6px 8px;
  border-radius: 2px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--gray_text);
}

.container:hover {
  background-color: var(--back);
}

.planName {
  font: var(--text_5_up_m);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 2px;
  margin-right: 4px;
  padding: 5px 3px 4px 4px;
}

.paymentFailedPlanName {
  composes: planName;
  background-color: var(--red);
}

.tooltip ul {
  margin: 0;
  padding-left: 20px;
}

.tooltip li {
  font: var(--text_6_m);
  font-size: 10px;
  line-height: 14px;
  color: var(--white);
}

.values {
  display: flex;
  align-items: center;
}

@media only screen and (max-device-width: 480px) {
  .values {
    display: none;
  }
}

.value {
  color: var(--gray_text);
  font: var(--text_5_m);
}
