.watchlistSelect, .numberSlider {
  width: 360px;
  min-height: 44px;
  margin-bottom: 12px;
}

// ===================
.cutoffInput {
  padding-top: 10px;
  padding-bottom: 24px;
}

.cutoffInput > div{
  margin-left: 0;
}

.largeInput {
  max-width: 120px !important;
}

.largeSelect > div {
  min-width: 240px !important;
}
//==================

.watchlistSelect > div {
  padding: 8px 8px 8px 8px;
}

.watchlistChip {
  width: calc(100% - 12px);
  background-color: var(--back_light);
  border-radius: 4px;
  padding: 8px;
}

.watchlistDesc {
  padding: 4px 4px 0 0;
 font: var(--text_10);
  line-height: 12px;
}

.menuItem, .menuItem > div {
  max-width: 350px;
  white-space: break-spaces;
  text-align: justify;
}

.inputRoot {
  min-height: 44px;
}

.ratingInput > div {
  width: 100%;
  margin-bottom: -2px;
}

.chipContainerOutlined {
  margin-top: 8px;
}

.chips {
  padding: 0;
  height: 24px;
  background-color: var(--back_light);
}

.tooltipFilter {
  margin-top: -16px;
}

.watchlistMainSelect {
  display: inline-block;
  width: 400px;
  position: relative;
}

.watchlistContainer {
  width: calc(100% - 32px);
}

.settingsButton {
  top: 32px !important;
  right: -24px !important;
}

.actionLink {
  font: var(--text_5_up);
  color: var(--blue);
  padding: 8px;
  top: -16px;
  position: relative;
  text-transform: uppercase;
}

.overlayDiv {
  position: absolute;
  width: 100%;
  height: 64px;
  display: flex;
  z-index: 100;
}

@media only screen and (max-device-width: 480px) {
  .watchlistSelect {
    width: calc(100vw - 96px) !important;
  }

  .watchlistMainSelect {
    width: 100% !important;;
  }

  .watchlistContainer {
    width: calc(100vw - 64px) !important;;
  }
}