.calendar > div > div > div{
  height: 300px !important;

}
.calendar > div > div {
  border: 0 !important;
  padding-top: 8px;
}

.day {
  position: relative;
  /*opacity: 10%;*/
  background-color: white !important;
  width: 40px !important;
  height: 40px !important;
  padding-top: 0 !important;
}

.red {
  color: var(--red) !important;
  /*color: var(--red);*/
  font-weight: bold;
}

.green {
  color: var(--green) !important;
  /*color: var(--green);*/
  font-weight: bold;
}

.gainBg {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100% !important;
  border-radius: 0px;
  height: 4px;
  /* opacity: 40%; */
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  color: var(--gray_dim);
}
