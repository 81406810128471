.giftsContainer {
  padding: 24px;
}

.shareWrapper {
  max-width: 500px;
}

.loading {
  max-height: 300px;
}

:global(.grsf-widget) {
  display: none !important;
}

:global(.grsf-block-form-powered-by) {
  height: 0;
  overflow: hidden;
}

:global(.grsf-global .grsf-share .grsf-share-buttons li .grsf-share-button),
:global(.grsf-global .grsf-share .grsf-copy-link .grsf-copy-link-button-container button.grsf-copy-link-button) {
  margin: 0 12px 0 0;
  background: #2196f3;
  box-shadow: 0 0 0 50px rgba(0, 0, 0, 0) inset !important;
  font-weight: 400 !important;
}

:global(.grsf-global .grsf-share .grsf-share-buttons li .grsf-share-button:hover),
:global(.grsf-global .grsf-share .grsf-copy-link .grsf-copy-link-button-container button.grsf-copy-link-button:hover) {
  color: #fff !important;
  box-shadow: 0 0 0 50px rgba(0, 0, 0, 0.1) inset !important;
}

:global(.grsf-global .grsf-share .grsf-copy-link input.grsf-share-url) {
  border: 1px solid #e0e0e0;
  box-shadow: none !important;
  height: 40px;
  padding-left: 13px;
  outline: none !important;
  border-right: none;
}

:global(.grsf-window-progress-block.grsf-reward-type-non-leaderboard.grsf-animation-vanish-in) {
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

:global(.grsf-window-progress-block.grsf-reward-type-non-leaderboard.grsf-animation-vanish-in span) {
  font-weight: 500 !important;
}

:global(.grsf-window-progress-block.grsf-reward-type-non-leaderboard.grsf-animation-vanish-in span + span) {
  display: none;
}

:global(.grsf-window-progress-block.grsf-reward-type-non-leaderboard.grsf-animation-vanish-in span:before) {
  content: "Referrals Made";
  display: block;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #979797;
  margin-bottom: 2px;
  letter-spacing: 1px;
}

.giftsContainer :global(.grsf-global div.grsf-window-progress-block) {
  box-shadow: none !important;
}

:global(.grsf-signup) {
  display: none;
}

.referralMessage {
  margin-top: 24px;
}

.referralMessage div,
.referralErrorMessage div {
  margin-bottom: 0;
}

.loading {
  max-height: 400px;
}