.paper {
  padding: 4px;
  box-shadow: var(--popup_shadow);
}

.buttonGroup.buttonGroup > button {
  border-right-color: #bdbdbd;
}

div[role="tooltip"] {
  z-index: 9999;
}