.actions {
  width: 80px;
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
}

.statusBar {
  margin-right: 28px;
}

.dataGrid.dataGrid tr:hover [class*="tickers"]::after {
  background: linear-gradient(
    to right,
    rgba(245, 245, 245, 0) 0%,
    rgba(245, 245, 245, 1) 100%
  );
}

.tickersHeader {
  max-width: 33vw;
  min-width: 10vw;
  width: 8vw;
}

.mobileTickers {
  color: var(--gray_text);
  max-width: 65vw;
}

@media only screen and (max-device-width: 480px) {
  .mobileWrapper .list {
    margin-top: 65px;
  }

  .mobileWrapper div[class^="Alert_container"] {
    margin-bottom: 80px;
  }

  .secondaryText > div:first-child {
    order: 2;
    max-width: 80%;
  }
}
