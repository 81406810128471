.wrapper {
  display: flex;
}

.borderBottom {
  border-bottom: 1px solid var(--gray_border);
}

.narrowRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px 8px;
  width: 100%;
  min-height: 24px;
  margin: 8px 16px;
}

.wideRow {
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 36px;
  margin: 12px 16px;
  flex-wrap: wrap;
  gap: 24px 24px;
}

.label {
  font: var(--text_5_up_m);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--gray_icon);
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.label.dark {
  color: var(--gray_text);
}

.label.blue {
  color: var(--blue);
  cursor: pointer;
}

.label.wide {
  padding-bottom: 2px;
}

.value {
  font: var(--text_5_m);
  color: var(--gray_text);
  padding: 4px;
  background: var(--back_light);
  border-radius: 2px;
  margin-right: 8px;
}

.narrowLabelVal {
  display: flex;
  align-items: center;
}

.wideLabelVal {
  display: flex;
  flex-direction: column;
}

.wideValue {
  font: var(--text_3_m);
}

.wideValue.green {
  color: var(--green);
}

.wideValue.red {
  color: var(--red);
}

.wideValue.dark {
  color: var(--gray_text);
}
