.container {
  border-radius: 4px;
  //margin: 0 8px 16px;
}

.row {
  min-height: 88px;
  padding: 24px 0;
  border-bottom: 1px solid var(--gray_border);
}

.row:last-child {
  border-bottom: none;
}

.rowLine {
  display: flex;
  align-items: center;
  padding: 0 36px;
  min-height: 36.5px;
  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .rowLine {
    padding: 6px 0 6px 12px;
  }
}

.icon {
  width: 32px !important;
}

.name {
  flex-grow: 1;
  flex-basis: 200px;
  margin-left: 24px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: var(--black);
}

.linkIcon {
  margin-right: 8px;
  height: 18px;
  width: 18px;
}

.buttonWrapper {
  margin-left: 12px;
}

.buttonWrapper span {
  line-height: 1.5;
}

.linkStatusTemplate {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;

  line-height: 1.5;
}

.linkStatusOK {
  composes: linkStatusTemplate;
  color: var(--green);
}

.linkStatusError {
  composes: linkStatusTemplate;
  color: var(--red);
}

.comingSoonButton {
  padding-left: 0;
}
//new styles
.availability,
.broker {
  gap: 9px;
}

.true {
  color: var(--green);
}

.false {
  color: var(--gray_icon);
}

.cells {
  display: flex;
}

.cell {
  display: flex;
  align-items: center;
  padding: 12px 11px;
  font: var(--text_4);
}

.email {
  color: var(--gray_text);
}

.gridContainer:not(:first-child) {
  border-top: 1px solid var(--gray_border);
}

.chevron,
.moreActions {
  color: var(--gray_icon);
  margin: 9px;
}

.broker.disabled {
  color: var(--gray_dim);

  & > img {
    opacity: 0.3;
  }
}

.mainRow :global(.MuiTableCell-root) {
  white-space: nowrap;
  padding: 0 !important;
  border-bottom: none;
  border-top: 1px solid var(--gray_border);
}

.mainRow:first-child :global(.MuiTableCell-root) {
  border-top: none;
}

.mainRow :global(.MuiTableCell-alignRight),
.collapseRow :global(.MuiTableCell-root:last-child) {
  width: 100%;
}

.collapseRow :global(.MuiTableCell-root) {
  white-space: nowrap;
}

.collapseRow :global(.MuiTableRow-root:last-child .MuiTableCell-body) {
  border-bottom: none;
}

.collapseRow :global(.MuiTableCell-head) {
  border-top: 1px solid var(--gray_border);
}

.message {
  padding: 0 24px;
}

.brokerTerms > div {
  margin: 0 24px 0 24px;
}
