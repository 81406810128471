.container {
  display: flex;
  position: relative;
  min-height: 41px;
  overflow: hidden;
}

.container > div {
  width: 100%;
}

.collapse {
  width: 100%;
}

.openButton {
  position: absolute;
  right: 16px;
  top: 9px;
  padding: 0;
  color: var(--gray_icon);
}

.short {
  position: absolute;
  background-color: var(--white);
  cursor: pointer;
}
