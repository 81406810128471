.row {
  position: relative;
  overflow: hidden;
  max-height: 26px;
}

.row::after {
  content: "";
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 255) 100%
  );
  height: 100%;
  width: 80px;
  right: 0;
  top: 0;
}

.item {
  font-weight: 500;
  margin-right: 5px;
  background: var(--back_light);
  border-radius: 4px;
  padding: 4px;
}
