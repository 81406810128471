.list {
  border-radius: 4px;
}

td.noVPadding.noVPadding.noVPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  > svg {
    margin-top: 8px;
  }
}

@media only screen and (max-device-width: 480px) {
  .mobileWrapper .list {
    margin-top: 74px;
  }
}
