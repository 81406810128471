.container {
  padding: 24px;
  background-color: var(--white);
}

.interval {
  width: 132px;
}

.exit > div > div[role="button"] {
  padding-right: 32px !important;
  min-width: 186px;
}

.interval > p {
  position: absolute;
  top: 39px;
}

.timeField {
  width: 132px;
}

.timeField label {
  white-space: nowrap;
  background: white;
}

.fullContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.gap8 {
  margin-right: -8px;
}

.timeInputsBlock {
  display: flex;
}

.mobileView > div {
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}
