.card {
  display: flex;
  position: relative;
  padding: 6px 40px 6px 8px;
}

.card.mobile {
  flex-direction: column;
}

.wideRow {
  border-bottom: none;
}

.wideRow > div {
  gap: 16px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0;
}

.wideRow.mobile > div {
  margin-right: 4px;
  margin-left: 4px;
}

.totalGainValue {
  font: var(--text_2_m);
}
