:global(.firebaseui-container) {
  padding-top: 16px !important;
}

:global(.firebaseui-id-idp-button) {
  max-width: 100% !important;
  border-radius: 4px !important;
}

:global(.firebaseui-idp-text) {
  text-transform: uppercase !important;
  color: var(--gray_label) !important;
  padding-left: 8.5px !important;
}

:global(.firebaseui-idp-icon-wrapper),
:global(.firebaseui-idp-text.firebaseui-idp-text-long) {
  display: inline !important;
}

:global(.firebaseui-card-content) {
  padding: 0 !important;
}

:global(.firebaseui-idp-button) {
  text-align: center !important;
  box-shadow: none !important;
  border: 1px solid var(--gray_border) !important;
}

:global(.firebaseui-idp-button:hover) {
  background-color: var(--back) !important;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  font: var(--text_4);
  color: var(--gray_label);
}

.container :global(.MuiLink-root) {
  cursor: pointer;
}

.logo {
  flex: 1;
  background: url("../../assets/auth_bg.png") no-repeat;
  background-size: cover;
  background-color: #e4f5fd;
  min-width: 50%;
}

.logo img {
  margin: 4.375% 0 0 5.5%;
  min-width: 110px;
}

.box {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 60px 0 48px;
  text-align: center;
  background-color: var(--white);
}

.content {
  max-width: 340px;
  width: 100%;
}

.title {
  color: var(--gray_text);
  font: var(--text_2_m);
  padding-bottom: 16px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form :global(.MuiFormHelperText-root.Mui-error) {
  top: 44px !important;
}

.passwordContainer {
  margin-bottom: 1.2em !important;
  position: relative;
}

.error {
  left: 0;
  bottom: -36px;
  position: absolute;
  color: var(--red);
}

.container :global(.MuiButton-root),
.container .submit {
  margin-top: 24px;
  padding-top: 8px;
  padding-bottom: 9px;
}

.input {
  width: 100%;
  margin-top: 8px;
}

.input input {
  border: 1px solid var(--gray_border);
  min-height: 40px;
  border-radius: 4px;
  padding: 0 14px !important;
}

.linksGroup {
  border-top: 1px solid var(--gray_border);
  margin-top: 38px;
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.dot:before {
  color: var(--gray_icon);
  content: "\2022";
  margin: 0 12px;
}

.or {
  border-top: 1px solid var(--gray_border);
  position: relative;
  margin-top: 39px;
  padding-bottom: 14px;
}

.or > span {
  color: var(--gray_icon);
  font: var(--text_4);
  position: absolute;
  top: -17px;
  background: var(--white);
  padding: 5px;
  left: calc(50% - 15px);
}

.form :global(.MuiInput-underline::before) {
  display: none;
}
.form :global(.MuiInput-underline::after) {
  border-bottom: none;
}

.form .input :global(.MuiInputLabel-root) {
  top: 8px;
  left: 12px;
  color: var(--gray_dim);
  background-color: var(--white);
  padding: 0 4px;
  position: absolute;
}

.form .input :global(.MuiInputLabel-root)[data-shrink="true"] {
  z-index: 100;
}

.form .input :global(.MuiInputBase-input) {
  padding: 0;
}

.form .input :global(.MuiFormHelperText-root) {
  position: absolute;
  margin-top: 13px;
  color: var(--red);
}

.form input[aria-invalid="true"] {
  border: 1px solid var(--red);
}

.linkText {
  margin-right: 6px;
  color: var(--gray_label);
}

.loginForm {
  min-height: auto !important;
  margin-top: -4px;
}

.loginForm form > div {
  padding: 0;
}

.loginForm button {
  margin-top: 2px;
}

.loginContent {
  max-width: 340px;
  width: 100%;
}

.loginForm input {
  min-height: 40px;
  width: 100%;
  border: 1px solid var(--gray_border);
  border-radius: 4px;
  padding: 0 14px !important;
}

.loginForm input[aria-invalid="true"] {
  border: 1px solid var(--red);
}

.loginForm :global(.MuiInputLabel-root) {
  top: 6px;
  left: 12px;
  color: var(--gray_dim);
  background-color: var(--white);
  padding: 0 4px;
  position: absolute;
}

.loginForm :global(.MuiInputLabel-root)[data-shrink="true"] {
  z-index: 100;
  transform: translate(0, 3px) scale(0.75) !important;
}

.loginForm :global(.MuiInput-underline::before) {
  display: none;
}

.loginForm :global(.MuiFormHelperText-root.Mui-error) {
  top: 54px !important;
  position: absolute;
}

.loginForm :global(.MuiButton-label) {
  text-transform: uppercase !important;
}

.loginForm :global(.MuiInput-root) {
  max-height: 40px;
}

.loginForm :global(.MuiInput-underline::before) {
  display: none;
}
.loginForm :global(.MuiInput-underline::after) {
  border-bottom: none;
}

@media only screen and (max-width: 320px) {
  .title {
    padding: 10px 0;
  }
}

@media only screen and (max-height: 680px) {
  .container {
    height: 100%;
  }
  .box {
    max-height: max-content;
  }
  .content {
    padding: 48px 0;
  }
}

@media only screen and (max-width: 1005px) {
  .logo {
    display: none;
  }
  .box {
    justify-content: center;
    padding-left: 60px;
  }
}

@media only screen and (max-width: 568px) {
  .box {
    padding-right: 32px;
    padding-left: 32px;
  }
}
