.message {
  /*margin: 0 24px 24px;*/
  padding: 24px 24px 16px;
  border-radius: 4px;
}

.agreement {
  composes: message;
  background-color: var(--blue_dim);
  border: 1px solid var(--blue);
}

.disclaimer {
  font: var(--text_4);
  color: var(--gray_label);
}

.disclaimer > div {
  padding-top: 16px;
  line-height: 20px;
}

.agreementButtons {
  margin-top: 8px;
  margin-left: -8px;
}

.agreeButton {
  color: var(--blue);
}

.cancelButton {
  color: var(--gray_label);
  margin-left: 16px;
}

.multiAcc {
  display: flex;
  flex-direction: column;
}

.multiAccHead {
  font: var(--text_4_m);
  margin: 16px 0 15px;
}

.multiAccItem {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  font: var(--text_4);
}

.multiAccLink {
  max-width: max-content;
  color: var(--blue);
  cursor: pointer;
}

.multiAccBuyingPower {
  color: var(--gray_text);
  padding: 0 4px;
  background: rgba(33, 150, 243, 0.15);
  border-radius: 2px;
}
