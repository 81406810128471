.text {
  margin: 0 10px;
}

.buttonGroup {
  height: 40px;
}

.long {
  color: var(--green);
  border-color: var(--green) !important;
}

.short {
  color: var(--red);
  border-color: var(--red);
}

.inactive {
  color: var(--gray_icon);
}

.inactive svg {
  stroke: var(--gray_icon);
}
