.gridLayout {
  padding: 24px 24px 64px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--white);
}

.gridLayout.mobile {
  padding: 16px 16px 72px;
}

.gridLayout.mobile .dates {
  flex-direction: column;
}

.gridLayout.mobile .dates > div {
  width: 100%;
}

.gridLayout.mobile .dates > div > div {
  height: 56px;
}

.dates {
  display: flex;
  gap: 0 8px;
}

.dates > div {
  margin-top: 8px;
}
