.datagrid th {
    height: 52px;
    /*padding: 0;*/
    padding-left: 24px;
}

.datagrid td {
    height: 49px;
    padding-left: 24px;
    /*padding: 0;*/
}