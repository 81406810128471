.wrapper {
  position: absolute;
  top: -64px;
  left: 0;
  right: 0;
  padding: 12px;
  background-color: var(--white);
  box-shadow: var(--block_shadow);
}

.button {
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
}

@media only screen and (max-device-width: 480px) {
  .button {
    flex: 1;
  }

  .button label {
    width: 100%;
    margin-left: 0 !important;
    justify-content: space-around;
  }
}

.button label {
  display: flex;
  align-items: center;
  padding: 10px 19px;
  border: 1px solid var(--gray_border);
  line-height: 1.35 !important;
  background-color: var(--white);
  font: inherit;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray_icon);
  cursor: pointer;
}

.button:first-child label {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.button:last-child label {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.button:nth-child(2n + 2) label {
  margin-left: -2px;
}

.button:last-child label {
  margin-left: -1px;
}

.buttons {
  display: flex;
}

.input {
  position: absolute;
  display: none;
}

.input:checked ~ label {
  font-weight: 500;
  color: var(--gray_text);
  border-color: var(--gray_icon);
  z-index: 1;
}
