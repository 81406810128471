.buttonGroup {
  height: 40px;
}

.active.active {
  border-color: var(--gray_text);
  border-right-color: var(--gray_text);
}

.inactive {
  color: var(--gray_icon);
}
