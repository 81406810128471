.textContainer {
  position: relative;
}

.textContainer:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid var(--gray_border);
  width: calc(100% + 32px);
  left: -16px;
}

.toolbar {
  border-top: 1px solid var(--gray_border);
  margin-top: -8px;
  background-color: var(--white);
}

.input {
  width: 350px;
  display: flex;
  margin-bottom: -2px;
}

.profileContainer {
  background-color: var(--white);
}

.profileContainer.mobile {
  position: relative;
}

.profileContainer.mobile :global(.MuiTabs-flexContainer) {
  justify-content: space-between;
}

.profileContainer.mobile .toolbar {
  position: absolute;
  bottom: -69px;
}

.profileContainer.mobile .input {
  max-width: 280px;
}

.profileContainer.mobile :global(.MuiTab-root) {
  font: var(--text_5_m);
}

.profileGrid {
  width: 100%;
  padding: 24px 24px 0 !important;
}

.bio {
  margin-top: 8px;
  margin-bottom: 28px;
}

.date {
  margin-top: 8px;
  margin-bottom: 0;
}
