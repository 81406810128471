.container {
    position: fixed;
    bottom: 16px;
    width: inherit;
    display: flex;
    flex-direction: column;
}

.link {
    background: var(--back);
    border-radius: 4px;
    padding: 14px;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    overflow: hidden;
}

.header {
    font-weight: 500;
    line-height: 22px;
    color: var(--gray_text);
    margin-bottom: 4px;
}

.text {
    line-height: 18px;
    color: var(--gray_icon);
    cursor: pointer;
    text-decoration: none;
}

.text:hover {
    text-decoration: underline;
}

.check_our_docs{
    margin-top: 6px;
}


.anim_help {
    animation: fontbulger 2s linear 0s infinite;
}

@keyframes fontbulger {
    0% {
        background-color: var(--back);
    }
    2% {
        background-color: var(--orange_dim);
    }
    10% {
        background-color: var(--back);
    }
    12% {
        background-color: var(--orange_dim);
    }
    100% {
        background-color: var(--back);
    }
}