.tos {
  padding: 24px 24px 54px 24px;
}

@media only screen and (max-device-width: 480px) {
  .tos {
    padding: 12px 24px 24px 24px;
  }
}

.tosContainer {
  background: var(--white);
}

.acceptButtonContainer {
  background: white;
  min-width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 88px;

  z-index: 1000;
}

.acceptButton {
  margin: 24px 48px;
  max-width: max-content;
  width: 100%;
  height: 40px;
  display: flex;
}

.tos > div {
  max-width: 1280px;
  margin: auto;
}

.tos span {
  line-height: 20px;
}

:global(.WordSection1) p,
:global(.WordSection1) span {
  font: var(--text_4) !important;
  color: var(--gray_text) !important;
}

:global(.WordSection1) b span {
  font-weight: 700 !important;
}

:global(.MsoNormal):nth-child(1) {
  margin-bottom: 6px !important;
}
:global(.MsoNormal):nth-child(1) b {
  color: var(--gray_text);
  font: var(--text_1);
  font-weight: var(--text_medium);
}

:global(.MsoNormal):nth-child(2) {
  max-width: max-content;
  padding: 0 4px;
  margin: 0 auto 30px !important;

  background-color: var(--back_light);
}

:global(.MsoNormal):nth-child(2) b {
  font-weight: 400;
}

:global(.MsoNormal):nth-child(3),
:global(.MsoNormal):nth-child(4),
:global(.MsoNormal):nth-child(5),
:global(.MsoNormal):nth-child(6),
:global(.MsoNormal):nth-child(7) {
  margin-bottom: 10px !important;
}

:global(.MsoNormal):nth-child(8),
:global(.MsoNormal):nth-child(13),
:global(.MsoNormal):nth-child(18),
:global(.MsoNormal):nth-child(22),
:global(.MsoNormal):nth-child(29),
:global(.MsoNormal):nth-child(30),
:global(.MsoNormal):nth-child(33),
:global(.MsoNormal):nth-child(42),
:global(.MsoNormal):nth-child(43),
:global(.MsoNormal):nth-child(50),
:global(.MsoNormal):nth-child(56),
:global(.MsoNormal):nth-child(59),
:global(.MsoNormal):nth-child(65),
:global(.MsoNormal):nth-child(77),
:global(.MsoNormal):nth-child(78) {
  margin-top: 26px !important;
}

:global(.MsoNormal):nth-child(8) b > span,
:global(.MsoNormal):nth-child(13) b > span,
:global(.MsoNormal):nth-child(18) b > span,
:global(.MsoNormal):nth-child(22) b > span,
:global(.MsoNormal):nth-child(29) b > span,
:global(.MsoNormal):nth-child(30) b > span,
:global(.MsoNormal):nth-child(33) b > span,
:global(.MsoNormal):nth-child(42) b > span,
:global(.MsoNormal):nth-child(43) b > span,
:global(.MsoNormal):nth-child(50) b > span,
:global(.MsoNormal):nth-child(56) b > span,
:global(.MsoNormal):nth-child(59) b > span,
:global(.MsoNormal):nth-child(65) b > span,
:global(.MsoNormal):nth-child(77) b > span,
:global(.MsoNormal):nth-child(78) b > span {
  margin-top: 26px !important;
  font: var(--text_2_m) !important;
}

:global(.MsoNormal):nth-child(8) b:first-child > span > span,
:global(.MsoNormal):nth-child(13) b:first-child > span > span,
:global(.MsoNormal):nth-child(18) b:first-child > span > span,
:global(.MsoNormal):nth-child(22) b:first-child > span > span,
:global(.MsoNormal):nth-child(29) b:first-child > span > span,
:global(.MsoNormal):nth-child(30) b:first-child > span > span,
:global(.MsoNormal):nth-child(33) b:first-child > span > span,
:global(.MsoNormal):nth-child(42) b:first-child > span > span,
:global(.MsoNormal):nth-child(43) b:first-child > span > span,
:global(.MsoNormal):nth-child(50) b:first-child > span > span,
:global(.MsoNormal):nth-child(56) b:first-child > span > span,
:global(.MsoNormal):nth-child(59) b:first-child > span > span,
:global(.MsoNormal):nth-child(65) b:first-child > span > span,
:global(.MsoNormal):nth-child(77) b:first-child > span > span,
:global(.MsoNormal):nth-child(78) b:first-child > span > span {
  display: inline-block;
  width: 8px !important;
}

:global(.MsoNormal):nth-child(8) span:nth-child(3),
:global(.MsoNormal):nth-child(29) span:nth-child(3),
:global(.MsoNormal):nth-child(42) span:nth-child(3),
:global(.MsoNormal):nth-child(65) span:nth-child(3),
:global(.MsoNormal):nth-child(77) span:nth-child(3) {
  display: block;
  margin-top: 10px;
}

@media only screen and (max-width: 500px) {
  .tos {
    padding-bottom: 64px;
  }
  .acceptButtonContainer {
    height: 60px;
  }
  .acceptButton {
    margin: 10px 24px;
  }
}
