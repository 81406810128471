.tickerItem {
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-bottom: 4px;
  margin-bottom: 22px;
}

.tickerItem:last-child {
  margin-bottom: 0;
}

.valueTemplate {
  flex-basis: 100px;
  flex-grow: 1;
  font: var(--text_5_up_m);
  text-transform: uppercase;
}

.valueGray {
  color: var(--gray_icon);
}

.tickerName {
  composes: valueTemplate;
}

.winLoss {
  composes: valueTemplate;
  text-align: right;
}

.rewardRisk {
  composes: valueTemplate;
  margin-left: 24px;
  text-align: right;
}

.barTemplate {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}

.barBackground {
  composes: barTemplate;
  right: 0;
  background-color: var(--gray_border);
}

.bar {
  composes: barTemplate;
  background-color: var(--blue);
}
