.buttonGroup {
  margin-left: 16px;
}

.noResults {
  background-color: var(--gray_icon) !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  min-height: 40px;
}

.button {
  padding: 8px 16px;
  letter-spacing: 0.3px;
  line-height: 19px;
  min-height: 40px;
  border-color: rgba(255, 255, 255, 0.3) !important;
  white-space: nowrap;
}

.popper {
  z-index: 10000;
  margin-top: 3px;
}

.popper > div {
  box-shadow: var(--popup_shadow);
}

.header {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--gray_icon);
  padding: 20px 16px 4px;
}

.menuItem {
  font-size: 16px !important;
  line-height: 20px;
  color: var(--blue);
  padding: 0 16px;
  min-height: 36px !important;
}

.menuItem:hover {
  background: rgba(33, 150, 243, 0.08);
}

.icon {
  height: 16px;
  margin-right: 6px;
  color: var(--blue);
}

.mobileMenuItem {
  font: var(--text_4);
  color: var(--blue);
  padding: 12px 11px;
  border-bottom: 1px solid var(--gray_border);
}

.mobileMenuItems {
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.mobileMenuItems .mobileMenuItemArrow {
  display: flex;
  align-items: center;
}

.mobileMenuItem .mobileMenuItemText {
  max-width: 90%;
}

.mobileMenuItem:first-child {
  border-top: 1px solid var(--gray_border);
}

.mobileMenu ul {
  padding-top: 0;
  padding-bottom: 0;
}

.root {
  background-color: var(--back);
  margin-top: 76px;
  padding: 0 12px 12px;
}

.root :global(.MuiDialog-paperFullScreen) {
  box-shadow: var(--block_shadow);
  border-radius: 4px;
}

.dialogTitle {
  font: var(--text_4_up_m);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--gray_icon);
  padding: 24px 11px;
}
