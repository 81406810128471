.searchInput {
  width: 100%;
  height: 40px;
  outline: none;
  color: var(--black);
  font-size: 16px;
  position: relative;
}

.searchInput::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid var(--blue);
  pointer-events: none;
}

.searchInput[class*="Mui-focused"]::after {
  transform: scaleX(1);
}

.clearSearch {
  margin-right: -10px;
}