.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 222px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray_dim);
  background-color: var(--white);
  padding: 40px;
}

.container > div {
  line-height: 24px;
}

.createButton {
  margin-top: 24px;
}

.hint {
  display: flex;
  flex-direction: column;
  text-align: center;
}
