.algoParams {
  width: 100%;
  box-shadow: var(--block_shadow);
  overflow: hidden;
  border-radius: 4px;
}

.showContainer {
  background-color: #fff;
  box-shadow: var(--block_shadow);
  border-radius: 4px;
}

.list {
  border-radius: 4px;
}

td.noVPadding.noVPadding.noVPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  > svg {
    margin-top: 8px;
  }
}

.toggleFilter label {
  white-space: nowrap;
}

@media only screen and (max-device-width: 480px) {
  .mobileWrapper .list {
    margin-top: 65px;
  }

  .mobileWrapper div[class^="Alert_container"] {
    margin-bottom: 80px;
  }
}
