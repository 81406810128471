.message {
  margin-top: 24px;
  padding: 24px 24px 16px;
  border-radius: 4px;
}

.errorMessage {
  composes: message;
  background-color: var(--red_dim);
  border: 1px solid var(--red);
}

.messageShow {
  display: block;
}

.messageHide {
  display: none;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  font: var(--text_3_m);
  color: var(--gray_text);
}

.icon {
  margin-right: 14px;
}

.text {
  font: var(--text_3);
  color: var(--gray_label);
  margin-top: 6px;
  margin-left: 32px;
}

.buttons {
  margin-left: -8px;
}

.relinkButton {
  color: var(--blue);
}

.relinkDescription {
  font: var(--text_3_m);
  color: var(--red);
}
