.button {
  height: 40px;
  white-space: nowrap;
}

.icon {
  margin-right: 10px;
}

.dashboardPage {
  padding-top: 8px;
  margin-left: -2px;
}

.sharedHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  margin-left: 2px;
}

@media only screen and (max-device-width: 480px) {
  .sharedHeader {
    margin: 24px 0 24px 2px;
  }
}

.algoContainer1Column {
  grid-template-columns: minmax(0, 1fr);
}

.algoContainer2Columns {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.algoContainer3Columns {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.algoContainer4Columns {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.algoContainer5Columns {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.algoContainer6Columns {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
