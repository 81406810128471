.screenerIcon {
  height: 17px;
  width: 17px;
  margin-bottom: -3px;
  color: var(--blue);
  cursor: pointer;
}

.ticker {
  color: var(--gray_text);
}

.benchmark {
  color: var(--gray_dim);
}