.wrapper {
    display: flex;
    justify-content: flex-end;
}

.menu :global(.MuiPaper-rounded) {
    border-radius: 4px !important;
    margin-left: 35px;
    margin-top: 6px;
    box-shadow: var(--popup_shadow);
}

.menu :global(.MuiPaper-rounded) > ul {
    padding-top: 0;
    padding-bottom: 0;
}

.itemHeader {
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    padding-left: 16px;
    padding-top: 12px;
}

.item {
    padding: 0 16px;
    font-size: 16px !important;
    line-height: 19px;
    color: var(--gray_text);
    min-height: 38px !important;
}

.item span > span{
    pointer-events: visible;
}

.label {
    padding-left: 10px;
    line-height: 19px;
}

.checkbox {
    padding: 8px;
}

.settingIcon {
    width: 16px;
    color: var(--gray_icon);
}

.settingsButton {
    float: right;
    margin-right: 1rem;
    padding: 5px;
    min-width: 34px;
    max-width: 34px;
    position: absolute;
    top: 9px;
    z-index: 3;
    right: -6px;
    height: 40px;
    background-color: #fff;
}
