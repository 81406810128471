.buttonGroup {
  height: 40px;
}

.button {
  color: var(--gray_icon);
}

.activeButton.activeButton {
  color: var(--gray_text);
  border-color: var(--gray_icon);
}
