.card, .strategyView {
  border-radius: 4px;
  width: 100%;
  background-color: white;
  position: relative !important;
}

.strategyView {
  /*padding-bottom: 16px;*/
  /*padding-right: 16px;*/
  /*padding-left: 16px;*/
}

.card > div, .strategyView > div {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
}

.title {
  padding-top: 12px;
  /*white-space: nowrap;*/
  display: flex;
  align-items: center;
  padding-bottom: 0;
  line-height: 20px;
  width: 100%;
  font-weight: bold;
}

.description, .descriptionFullText {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0;
  color: var(--gray_label);
}

.description p, .descriptionFullText p {
  color: var(--gray_label);
  line-height: 24px;
  font-size: 14px;
}

.description div[class='image'], .descriptionFullText div[class='image'], .description p:not(:first-child), .description hr {
  display: none !important;
}

.tags {
  /*padding: 12px;*/
}

.tag {
  font-size: 0.875rem;
  /*color: var(--gray_dim);*/
  /*font-weight: bolder;*/
  border-radius: 4px;
  background-color: var(--back_light);
  margin-right: 8px;
  padding: 4px;
}

.imageBox {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.image {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.actionsRight {
  text-align: right;
  padding-bottom: 16px;
}

.actionsLeft {
  text-align: left;
  padding-bottom: 16px;
  padding-top: 16px;
}

.viewButton {
  margin-right: 8px;
}

.tradeButton {
  background-color: var(--blue) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  color: var(--white);
}

.performance span {
  font-size: 1.0rem !important;
}
.performance {
  font-size: 0.875rem;
}

.pHeader {
  font-weight: bolder;
  color: var(--gray_label);
}