.mobileActions {
  border-bottom: 1px solid var(--gray_border);
}

.backtestResults [class*="MuiCard"] {
  overflow: unset;
  box-shadow: none;
  border-radius: unset;
  background-color: transparent;
}

.backtestResults > div {
  margin: 0;
}

.archivedView {
  padding-left: 35px !important;
}

.backtestToolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: initial !important;
}

.backtestList > thead > tr {
  height: 58px;
}

.errorTooltip {
  white-space: pre-line;
}

.moreActions {
  margin-left: auto;
}

.screenerIcon {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.linearProgress {
  min-width: 140px;
  display: flex;
  flex-direction: column;
}

.linearProgress :global(.MuiLinearProgress-root) {
  height: 2px;
  border-radius: 2px;
}

.linearProgress :global(.MuiLinearProgress-colorPrimary) {
  background-color: var(--red);
}

.linearProgress :global(.MuiLinearProgress-barColorPrimary) {
  background-color: var(--green);
  border-right: 2px solid var(--white);
}
.emptyLinearProgress :global(.MuiLinearProgress-barColorPrimary) {
  background-color: var(--gray_dim) !important;
}

.linearProgressValues {
  display: flex;
  font: var(--text_3_m);
  margin-top: 0;
  margin-bottom: 2px;
}

.linearProggressContainer {
  max-width: 140px;
}

.positive {
  color: var(--green);
}

.negative {
  color: var(--red);
}

.empty {
  color: var(--gray_dim);
}

.listDesktop table tbody > tr {
  height: 55px;
}

.listMobile > :global(.MuiToolbar-regular) {
  display: flex;
  flex-direction: column-reverse;
  max-height: initial !important;
  padding: 0 !important;
}

.listMobile > :global(.MuiToolbar-regular) form,
.listMobile > :global(.MuiToolbar-regular) div:not(:global(.MuiInputAdornment-root)) {
  width: 100%;
}

.listMobile .backtestToolbar {
  padding: 0 !important;
}

.listMobile .backtestToolbar > div {
  position: relative;
  top: 0;
  border-bottom: 1px solid var(--gray_border);
  box-shadow: none;
  background-color: initial;
}

.listMobile > :global(.MuiToolbar-regular) form {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.alertContainer {
  padding: 0 10px;
}

.alertContainer > div {
  margin-bottom: 0 !important;
}

.wrapper {
  display: flex;
  gap: 0 24px;
}

.wrapper.mobile {
  width: 100%;
}

.noImage p, .noImage ul {
  color: var(--gray_label);
  line-height: 24px;
  font-size: 14px;
}

.noImage div[class='image'] {
  display: none !important;
}