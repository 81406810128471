.picker {
  width: 100%;
  height: 62px;
}

.picker > label:not([data-shrink=true]) {
  /*transform: translate(14px, 11px) scale(1);*/
  /*height: 20px;*/
  /*padding-top: 1px;*/
  transform: translate(14px, 1px) scale(1);
  height: 38px;
  padding-top: 11px;
  color: var(--gray_icon) !important;
}

.picker > div {
  padding-right: 0;
}

.picker input {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

.picker label {
  white-space: nowrap;
  /*background-color: white;*/
  padding-right: 4px;
}

.clockIcon {
  width: 20px;
}
