.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.reactFlagsSelect {
  padding: 0;
  margin-right: 8px;
  position: relative;
}

.reactFlagsSelect > button {
  padding: 0;
  background: none;
  border: none;
}

.reactFlagsSelect > button::before,
.reactFlagsSelect > button::after {
  content: none;
}

.reactFlagsSelect > button > span {
  padding: 0;
  margin: 0;
  display: inline-flex;
}
