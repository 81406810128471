.toolbar {
  background-color: var(--white);
  padding: 0 24px 24px !important;
  border-radius: 4px !important;
  margin: -64px 0 0;
}

.toolbar.mobile {
  position: relative;
  padding: 0 16px 24px !important;
}

.save {
  height: 40px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.save svg {
  display: none;
}

.recentButton {
  height: 36px;
  margin-left: auto;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray_label) !important;
}

.saveButton {
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  width: 173px;
  justify-content: flex-start;
  color: var(--blue);
}

.cancel {
  height: 36px;
  margin-left: 24px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray_label) !important;
}

.spinner {
  margin-right: 40px;
  color: var(--blue);
  width: 20px !important;
  height: 20px !important;
}
