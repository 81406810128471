.container {
  margin-right: auto;
  display: flex;
}

.breadcrumbItem {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font: var(--text_5_up_m);
  color: var(--gray_icon);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 8px;
  outline: none;
  max-width: 38vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumbDelimiter {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: var(--gray_icon);
}

.breadcrumbItem:disabled {
  color: var(--gray_text);
  cursor: default;
}

@media only screen and (max-device-width: 480px) {
  .breadcrumbDelimiter {
    display: none;
  }

  .breadcrumbItem {
    display: none;
  }

  .breadcrumbItem:disabled {
    display: block;
  }
}
