.tradingParams {
  width: 100%;
  box-shadow: var(--block_shadow);
  overflow: hidden;
  border-radius: 4px;
}

.backtests {
  padding: 24px 24px 0;
  width: 100%;
  box-shadow: var(--block_shadow);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--white);
}

.save {
  height: 40px;
  width: 165px;
  font-weight: 500;
  font-size: 14px;
  justify-content: flex-start;
}

.cancel {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  margin-left: 24px;
  color: var(--gray_label) !important;
}

.toolbar {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

.saveButton svg {
  display: none;
}

.recentButton {
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray_label) !important;
}