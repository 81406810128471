.container {
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  padding: 12px 18px;
  width: 100%;
}

.brand {
  background: var(--blue_dim);
  border-color: var(--blue);
}

.warning {
  background: var(--orange_dim);
  border-color: var(--gray_border);
}

.success {
  background: var(--green_dim);
  border-color: var(--green);
}

.danger {
  background: var(--red_dim);
  border-color: var(--red);
}

.default {
  background: var(--back_light);
  border-color: var(--gray_dim);
}

.typeIcon {
  margin-right: 14px;
}

.header {
  font: var(--text_4_m);
  color: var(--gray_text);
}

.header:not(:first-child) {
  margin-top: 25px;
}

.description {
  font: var(--text_4);
  color: var(--gray_label);
}

.container.mobile {
  margin-bottom: 12px;
}

.container.mobile + form {
  margin-top: 24px;
}

.container.mobile .description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container.mobile .description > button {
  margin-left: 0 !important;
  margin-top: 2px !important;
}

.description p {
  margin: 6px 0;
}

.icon {
  margin-left: auto;
}

.actionButtonWrapper {
  width: fit-content;
}

.action {
  font: var(--text_4_up_m);
  padding-left: 0;
  padding-right: 0;
  margin-top: 13px;
  margin-left: -3px;
  text-transform: uppercase;
}

.mobileLabel {
  display: none;
}

@media only screen and (max-device-width: 480px) {
  .mobileLabel {
    display: inline;
    font-size: 0.8125rem;
  }
}

.action:hover {
  background-color: transparent !important;
}

.action.brand {
  color: var(--blue) !important;
}

.action.warning {
  color: var(--orange) !important;
}

.action.success {
  color: var(--green) !important;
}

.action.danger {
  color: var(--red_light) !important;
}

.action.default {
  color: var(--gray_label) !important;
}

.action.disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.link {
  color: var(--blue);
  text-decoration: none;
}
