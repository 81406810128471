.medal {
    height: 42px;
    vertical-align: middle;
    display: inline-block;
    padding-top: 14px;
}

.gold, .silver, .bronze {
    position: relative;
    top: 10px;
    left: 6px;
}

.gold svg {
    color: goldenrod !important;
}

.silver svg {
    color: silver !important;
}

.bronze svg {
    color: darkgoldenrod !important;
}

.brokerIcon {
    max-width: 24px;
    display: inline-block;
    position: relative;
    top: 4px;
}

.secondaryText {
    padding-top: 12px;
}
.secondaryText div, .primaryText div {
    display: inline-block;

}

.primaryText * {
    font-size: 0.975rem;
}

.primaryText {
    position: relative;
}

.mobileRank {
    position: absolute;
    right: 0;
    top: -6px;
}

.primaryText .medal {
    height: auto;
    padding-top: 0;
}

.primaryText .gold, .primaryText .silver, .primaryText .bronze {
    top: 8px;
    margin-left: 6px;
    margin-right: 6px;
}

.simpleList > span > * {
    border-bottom: 1px solid var(--gray_border);
}

.tertiaryText > span {
    font-size: 1.0rem;
    position: relative;
    top: 8px;
    font-weight: 400;
}

.userName {
    color: var(--blue);
}

