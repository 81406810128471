.brokers {
  background-color: var(--white);
  line-height: 12px;
}

.label {
  gap: 0 8px;
}

.icon {
  display: flex;
  width: 24px;
}
