.subscriptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.grid {
  width: 100%;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 0;
}

.plan {
  width: 100%;
  list-style-type: none;
}

.subscriptionsContainer {
  max-width: 1045px;
  width: 100%;
}

.limitOld {
  text-decoration: line-through;
  padding-right: 4px;
}

.list {
  padding: 0;
  margin: 0;
}

.value {
  width: 100%;
  text-transform: capitalize;
  display: flex;
}

/*icon*/
.value > span {
  position: relative;
  top: -1px;
}

.suffix {
  display: block;
  padding-left: 25px;
  color: var(--blue);
}

.listItem {
  display: flex;
  font: var(--text_5);
  color: var(--gray_text);
  list-style: none;
  margin-bottom: 10px;
}

.listItemDisabled {
  color: var(--gray_dim);
}

.link {
  color: var(--blue);
  text-decoration: none;
}

.trial_box {
  line-height: 18px;
  color: var(--gray_icon);
  cursor: pointer;
  text-decoration: none;
  display: inline;
}

@media only screen and (max-device-width: 480px) {
  .trial_box {
    text-align: justify;
  }
}
