.wrapper {
  position: relative;
}

.link {
  color: #999999;
  font-size: 9px;
  text-decoration: none;
  position: absolute;
  right: 45%;
  left: 45%;
  display: flex;
  justify-content: center;
  bottom: 5px;
}