.plan {
  border-radius: 4px;
  height: 100%;
  padding: 12px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.planInactive {
  box-shadow: 0 0 0 1px var(--gray_border);
}

.planActive {
  box-shadow: 0 0 0 2px var(--green);
}

.planFailed {
  box-shadow: 0 0 0 2px var(--red);
}

.iconContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}

.mostPopular {
  font: var(--text_5_up_m);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px;
  border-radius: 2px;
  color: var(--white);
  background-color: var(--blue);
}

.headerLabel {
  font: var(--text_3_m);
  color: var(--gray_text);
  line-height: 24px;
  vertical-align: middle;
  height: 24px;
  display: flex;
}

.headerLabel svg {
  margin-right: 12px;
}

.description {
  font: var(--text_5);
  color: var(--gray_icon);
  margin-top: 4px;
  margin-bottom: 18px;
  height: 64px;
}

.planDetails .description {
  height: 24px;
}

.price {
  font: var(--text_1_m);
  font-weight: 900;
  letter-spacing: -0.24px;
}

.price::after {
  content: "\00a0 ";
}

.oldPrice {
  font: var(--text_2_m);
  font-weight: 900;
  letter-spacing: -0.24px;
  text-decoration: line-through;
}

.nonePrice {
  display: none;
}

.planDetails {
  height: 100%;
  position: relative;
}

.activateButton {
  position: absolute;
  bottom: 0;
}

.status {
  font: var(--text_4_up_m);
  text-transform: uppercase;
  padding: 13px;
  width: 100%;
  margin-top: 17px;
}

.active {
  display: inline-flex;
  justify-content: center;
  color: var(--green);
}

.select {
  color: var(--white);
  background: var(--blue) !important;
  box-shadow: var(--btn_shadow);
  border-radius: 4px;
  transition: 0.2s ease !important;
}

.select:hover {
  box-shadow: var(--btn_shadow), 0 0 0 100px rgb(0 0 0 / 10%) inset !important;
}

.discountDuration {
  border-radius: 2px;
  display: inline-block;
  background-color: var(--back_light);
  padding: 2px 2px;
  font: var(--text_5);
  color: var(--gray_text);
  margin-top: 7px;
}

.periodEnds {
  display: inline;
}
