.date,
.score {
  max-width: 2vw;
  min-width: 1vw;
  width: 1vw;
  white-space: nowrap;
}

.date > span,
.date > time {
  padding-left: 24px !important;
}

.tickersHeader {
  max-width: 10vw;
  min-width: 10vw;
  width: 10vw;
  white-space: nowrap;
}

.datagrid.datagrid tr:hover [class*="tickers"]::after {
  background: linear-gradient(
    to right,
    rgba(245, 245, 245, 0) 0%,
    rgba(245, 245, 245, 1) 100%
  );
}

.boolFilter {
  top: -20px;
  left: 14px;
  max-height: 29px;
  position: relative;
}

.boolFilter :global(.MuiFormLabel-filled) {
  display: none;
}

.resultList table tbody > tr {
  height: 55px;
}
