@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

/* variables */
:root {
  --blue: #2196f3;
  --blue_dim: #f2f9fe;
  --back: #f4f6f8;
  --back_light: #f7f9fa;
  --red: #e02d2d;
  --red_light: #ff3636;
  --red_dim: #fbf1f3;
  --orange: #f57c00;
  --orange_dim: #fff3e0;
  --green: #009a06;
  --green_dim: #f2f8f3;
  --black: #000000;
  --gray_text: #383838;
  --gray_label: #757575;
  --gray_icon: #979797;
  --gray_dim: #bcbcbc;
  --gray_border: #e0e0e0;
  --white: #ffffff;

  --popup_shadow: 0 3px 6px rgba(0, 0, 0, 0.22);
  --btn_shadow: 0 1px 1px rgba(0, 0, 0, 0.14);
  --header_shadow: 0 2px 4px rgba(63, 63, 68, 0.08);
  --block_shadow: 0 1px 2px rgba(63, 63, 68, 0.15);
  --block_shadow_error: 0 1px 2px rgba(224, 45, 45, 0.22);
  --border_bottom_shadow: inset 0 -1px 0 var(--gray_border);

  --main_font: "Roboto", "Helvetica", "Arial", sans-serif;

  --text_medium: 500;
  --text_1: 35px / 40px var(--main_font);
  --text_1_m: var(--text_medium) var(--text_1);
  --text_2: 24px / 28px var(--main_font);
  --text_2_m: var(--text_medium) var(--text_2);
  --text_3: 16px / 20px var(--main_font);
  --text_3_m: var(--text_medium) var(--text_3);
  --text_4: 14px / 24px var(--main_font);
  --text_4_m: var(--text_medium) var(--text_4);
  --text_4_up: 14px / 14px var(--main_font);
  --text_4_up_m: var(--text_medium) var(--text_4_up);
  --text_5: 12px / 16px var(--main_font);
  --text_5_m: var(--text_medium) var(--text_5);
  --text_5_up: 12px / 12px var(--main_font);
  --text_5_up_m: var(--text_medium) var(--text_5_up);
  --text_6: 9px / 9px var(--main_font);
  --text_10: 10px / 10px var(--main_font);
  --text_6_m: var(--text_medium) var(--text_6);
  --text_code: 13px / 16px "Roboto Mono", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
}

* {
  font-family: var(--main_font) !important;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--back);
}

.ace_editor,
.ace_editor * {
  font: var(--text_code) !important;
}

.MuiInputLabel-outlined {
  color: var(--gray_icon) !important;
}

@-moz-document url-prefix() {
  .MuiTableCell-head {
    position: static !important;
  }
}

.MuiTableCell-head {
  line-height: 1rem !important;
}

.MuiTableCell-root {
  line-height: 26px !important;
}

.MuiAccordion-root:before {
  top: 0px !important;
}

.Width-100p {
  width: 100% !important;
}

.page-number {
  min-width: 25px !important;
}

.MuiTablePagination-selectRoot {
  margin-right: 5px !important;
}

.Other-Col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 10vw;
  min-width: 6vw;
  width: 8vw;
}

.Short-Col {
  max-width: 7vw;
  min-width: 5vw;
  width: 5vw;
}

.Very-Short-Col {
  max-width: 1vw;
  min-width: 1vw;
  width: 1vw;
}

.Other-Header {
  white-space: nowrap;
}

.Name-Col {
  max-width: 24vw;
  min-width: 24vw;
  width: 24vw;
}

.Short-Name-Col {
  max-width: 12vw;
  min-width: 12vw;
  width: 12vw;
}

.User-Role {
  padding: 0px 1px;
}

.User-Role:not(:last-child)::after {
  content: ";";
}

.Fake-Col {
  min-width: 1vw;
  max-width: 1vw;
  width: 1vw;
}

.Status-Col {
  padding-left: 11px !important;
  padding-right: 15px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  min-width: 30px;
  max-width: 6vw;
  width: 5vw;
}

.TVWidget {
  padding-top: 10px;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px !important;
}

.Cancel-Button {
  margin-left: 5px !important;
}

.User-Tabs .MuiTabs-scroller {
  background: white;
}

.User-Tab {
  display: block;
  padding: 15px;
  background: white;
}

/* Arrow icon in accordion */
.MuiAccordionSummary-content {
  order: 2;
  margin-left: 16px !important;
}

.MuiAccordionDetails-root {
  display: flex;
  flex-direction: column;
}

.MuiAccordionSummary-expandIcon {
  margin-left: 12px !important;
}

.MuiAccordion-root > .Mui-expanded {
  border-bottom: 2px solid var(--gray_border);
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

/* SELECT INPUT */
.select-input__sel select {
  padding: 10px 15px !important;
}

/* RANGE SLIDER */
.range-slider__wrapper {
  background-color: var(--back_light);
  outline: 12px solid var(--back_light);
  border-radius: 4px;
  padding: 0 2px;
}

.range-slider__wrapper:nth-child(2n + 2) {
  margin-bottom: 24px;
}

.range-slider__wrapper:nth-child(2n + 1) {
  margin-bottom: 24px;
}

.range-slider__inputs {
  padding-bottom: 0;
}

.range-slider__inputs .MuiTextField-root {
  padding-right: 12px;
}

.range-slider__inputs .MuiTextField-root input {
  background: var(--white) !important;
}

.range-slider__inputs .MuiFormHelperText-filled {
  /*display: none;*/
  max-height: 10px;
}

/*Trading hours slider*/
.range-slider__ranger {
  width: 510px;
}

.range-slider__label {
  padding-bottom: 0;
  font-weight: bolder;
}

.range-slider__ranger .MuiSlider-mark {
  border-left: 1px solid var(--gray_border);
  height: 24px !important;
  top: 0;
  background: none !important;
}

.range-slider__ranger .MuiSlider-markLabel {
  margin-left: 10px;
  margin-top: 2px;
  font-size: 12px;
}

.range-slider__ranger .MuiSlider-markLabel:nth-child(5) {
  margin-left: 16px;
  margin-top: 2px;
  font-size: 12px;
}

.range-slider__ranger .MuiSlider-root {
  color: var(--blue);
}

.range-slider__sections {
  font-size: 12px;
  text-transform: uppercase;
}

.Market-Hours-Sergment {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}

.Regular-Days {
  /*9:00am - 6:00pm = 540 minutes -> we use 1.8 scale here*/
  width: 972px;
}

.Early-Close {
  /*9:00am - 1:00pm = 270 minutes* -> we use 1.8 scale here*/
  width: 432px;
}

/*Buttons*/
.MuiButton-containedPrimary {
  color: var(--white);
  background-color: var(--blue) !important;
  box-shadow: var(--btn_shadow) !important;
  transition: 0.2s ease !important;
}

.MuiButton-containedPrimary:hover {
  box-shadow: var(--btn_shadow), 0 0 0 100px rgb(0 0 0 / 10%) inset !important;
}

.MuiButton-textPrimary {
  color: var(--blue) !important;
}

.MuiButton-textPrimary:hover {
  background-color: var(--blue_dim) !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--blue) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--blue) !important;
}

.MuiTypography-colorPrimary {
  color: var(--blue) !important;
}

.MuiTabs-indicator {
  background-color: var(--blue) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--blue) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--blue) !important;
}

.Market-Hours-Only .MuiFormControlLabel-label {
  font-size: 12px !important;
  text-transform: uppercase;
}

/*criteria editor*/

.ace_placeholder {
  margin-left: 0;
}

.ace_gutter {
  background-color: var(--back_light) !important;
}

.list-page {
  box-shadow: var(--block_shadow);
}

.list-page .MuiToolbar-regular .MuiButton-containedPrimary {
  line-height: 19px;
  font-size: 0.875rem;
}

/*Table Filters + Tables*/
.list-page > .MuiToolbar-regular {
  min-height: 24px !important;
  max-height: 66px;
  padding-top: 0 !important;
  padding-right: 24px;
  padding-left: 24px !important;
  background: var(--white) !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: inset 0 -1px 0 var(--gray_border);
}

@media only screen and (max-device-width: 480px) {
  .list-page > .MuiToolbar-regular {
    padding-right: 4px;
    padding-left: 16px !important;
  }
}

.filter-field .MuiInput-underline:before {
  border: 0 none !important;
  border-color: var(--blue);
}

.filter-field .MuiInput-underline:after {
  border-bottom: 1px solid var(--blue) !important;
}

.filter-field .MuiFormHelperText-marginDense {
  margin-top: 6px;
}

.MuiPaper-rounded {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.filter-field {
  padding-bottom: 6px;
}

/********************************/
/*   DASHBOARD & SHARED ALGOS   */
/********************************/

.Bechmark-Chart-Return {
  color: var(--blue);
  font-size: 12px;
}

.Bechmark-Chart-Benchmark {
  color: var(--gray_label);
  font-size: 12px;
}

.dashboard-page {
  padding-top: 8px;
}

.shared-algos {
  display: flex;
  flex-direction: column;
}

.shared-algos__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.shared-algos__content {
  display: flex;
}

.shared-algos__content .algo-card_wrapper {
  margin-right: 32px;
  margin-bottom: 32px;
}

/********************************/
/*      CREATE & EDIT ALGOS     */
/********************************/
.edit-algo .ra-input {
  margin-bottom: 24px;
  padding-top: 8px;
}

.visibility-options .checkbox__label {
  padding-bottom: 14px;
  margin-right: 0 !important;
}

.MuiCircularProgress-colorPrimary {
  color: var(--blue) !important;
}

.light-chart-label {
  position: absolute;
  padding: 7px 14px;
  font-size: 12px;
  color: var(--gray_label);
}

.light-chart-label-return {
  color: var(--blue);
}

.Dashboard-Algo-Container {
  width: 100%;
  display: grid;
  grid-gap: 24px;
}

.Dashboard-Algo .MuiPaper-rounded {
  border-radius: 4px !important;
  box-shadow: var(--block_shadow);
}

.Backtests-Filter {
  padding-top: 4px !important;
}

.MuiChip-root {
  border-radius: 4px !important;
}

.Backtests-Filter .MuiChip-label {
  padding: 0 6px;
}

.MuiTableCell-body.Actions-Col {
  padding: 11px !important;
}

.MuiTableCell-body:not(.MuiTableCell-sizeSmall):not(.Expand-Cell) {
  padding: 6px 27px 6px 11px;
}

.Expand-Cell {
  padding: 0 !important;
}

.MuiTableCell-paddingCheckbox {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.MuiTableCell-head:not(.MuiTableCell-sizeSmall):not(.Empty-Col) {
  padding-left: 11px;
  padding-right: 27px;
}

.MuiTableCell-head:not(.MuiTableCell-sizeSmall) > span,
.MuiTableCell-body:not(.MuiTableCell-sizeSmall) > span,
.MuiTableCell-body > a {
  line-height: 20px;
  /*max-height: 12px;*/
  /*min-height: 12px;*/
  /*height: 12px;*/
}

.select-all,
.select-item {
  /*width: 12px;*/
}

.Expand-Row td {
  padding: 0 !important;
}

/*ORDER ICONS*/
.icon-COMPLETED {
  color: var(--green);
}

.icon-EXPIRED,
.icon-CANCELED,
.icon-REJECTED,
.icon-MARGIN,
.icon-EXPIRED,
.icon-FAILED {
  color: var(--red) !important;
}

.icon-DIMMED {
  color: var(--gray_dim);
}

.MuiToggleButton-root .Mui-selected {
  background-color: var(--blue) !important;
}

.User-Edit {
  background-color: var(--white);
}

.User-Edit .toolbar,
.User-Toolbar {
  padding-left: 24px !important;
}

.User-Create {
  background-color: var(--white);
  padding: 24px 24px 0;
}

.User-Create .MuiToolbar-root {
  padding: 0;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
  padding: 3px;
  min-height: 40px;
}

svg.MuiSvgIcon-root.MuiChip-deleteIcon {
  height: 16px;
}

.Empty-Col {
  min-width: 15px;
  width: 15px;
  padding: 0 !important;
}

.IdsWrapper-Col {
  display: none;
}

/*Cancel button*/
.MuiButton-label > span {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

/*pagination*/
.MuiTablePagination-root .MuiTablePagination-toolbar {
  background: transparent !important;
}

.Sidebar-Close .MuiMenuItem-root {
  color: transparent !important;
}

header button[aria-label="Refresh"] {
  display: none;
}

[id^="ga-event"] > * {
  pointer-events: none;
}

.noclick {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 9999999px;
  width: 100%;
}

/* MVP1-1965 */
.MuiTableSortLabel-icon {
  color: var(--gray_icon) !important;
  position: absolute;
  right: -26px;
}

.inputGrey {
  background-color: var(--back_light);
}

.inputGrey input,
.inputGrey svg {
  color: var(--gray_dim);
}

#grsf-referred-experience {
  display: none;
}
