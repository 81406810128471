.gridLayout {
  padding: 24px 24px 64px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--white);
}

.gridLayout.mobile {
  padding: 24px 16px 88px;
}

.initialCapital :global(.MuiFormHelperText-marginDense) {
  margin-top: 3px;
}

.benchmark {
  width: 200px;
}

.stockInput {
  margin: 0 0 16px;
}

.row {
  display: flex;
  gap: 0 16px;
}

.gridLayout.mobile .row {
  flex-direction: column;
}

.gridLayout.mobile .benchmark {
  width: 100%;
}

.gridLayout.mobile .initialCapital > div > div {
  margin-bottom: 2px;
}

.gridLayout.mobile .benchmark > div {
  margin: 0;
}

.popper {
  margin-top: -27px;
  z-index: 1500;
  pointer-events: none;
}
