.container {
  margin-bottom: 16px;
  background: var(--red_dim);
  border: 1px solid var(--red);
  box-shadow: var(--header_shadow);
  border-radius: 4px !important;
}

.expandIcon {
  color: var(--gray_icon);
}

.heading {
  font: var(--text_4_m);
  color: var(--red);
}

.summary {
  flex-direction: row-reverse;
}

.expandIcon {
  margin-left: 0 !important;
  margin-right: 18px;
}

.summary > div {
  display: flex;
  flex-direction: column;
  margin: 12px 18px !important;
  font: var(--text_4);
  color: var(--gray_label);
}

.details {
  margin-top: -8px;
  padding: 0 16px 16px;
}

.expandPanel {
  border-radius: 4px !important;
  background-color: var(--red_dim);
}

.expandPanel > div {
  border-bottom: 0 !important;
}

.expandPanel::before {
  display: none;
}

.contactSupportButton {
  font: var(--text_5_up_m);
  color: var(--blue);
}

.contactSupportLink {
  color: var(--blue);
}

.supportSummary {
  margin-left: -8px;
}
