.container {
  height: 36px;
  margin-bottom: 24px;
}

.label {
  font: var(--text_4_up_m);
  color: var(--gray_icon);
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  line-height: 12px;
  margin: 0px;
  font-size: 12px;
}

.content {
  height: 24px;
  display: flex;
  align-items: center;
  font: var(--text_3_m);
  color: var(--gray_text);
}
