.index {
  color: var(--blue);
  display: inline-flex;
  align-items: center;
}

.briefcase {
  height: 12px;
  width: 12px;
  margin-right: 4px;
}

.label {
  width: 100%;
}
