.menu {
  margin: 0 !important;
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  justify-content: flex-start;
}

.activeMenuItem {
  color: var(--blue);
}

.menuItemReadOnly {
  font: var(--text_4);
  color: var(--gray_text);
  padding: 11px 4px;
  min-height: 46px;
  line-height: 23px;
}

.menuItemReadOnly > div {
  color: var(--gray_text);
  min-width: auto;
  margin-left: 11px;
  margin-right: 10px;
  display: inline-flex;
}

.menuItemReadOnly > div > span > svg {
  color: var(--gray_text);
}

.menuItem {
  color: var(--gray_text);
}

.menuItem svg {
  color: var(--gray_text) !important;
}

.menuItemReadOnly, .menuItemReadOnly svg {
  color: var(--gray_label) !important;
}

.menuItem svg, .menuItemReadOnly svg {
  height: 20px;
  width: 20px;
  position: relative;
  top: 4px;
}

.activeMenuItem, .activeMenuItem svg, .menuItem:hover svg {
  color: var(--blue) !important;
}

.sidebarClosed .menuItem  {

}
