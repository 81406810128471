.list {
  background-color: white;
  border-radius: 4px;
}

.create {
  margin-bottom: 1px;
}

.datagrid {

}

.list > div {
  display: flex;
}

/*optimization for mobile friendly view*/
.simpleList > a > * {
  border-bottom: 1px solid var(--gray_border);
}

.mobileName {
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.role:not(:first-child) {
  margin: 0px 4px;
}

.role > * {
  height: 18px;
  margin: 4px 0;
}

.secondaryText > div:first-child {
  max-width: 75vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.secondaryText {
  white-space: nowrap;
}

.secondaryText > div:first-child {
  margin: 0px -4px;
  display: block;
}

.secondaryText > div {
  white-space: nowrap;
}

.tertiary {
  opacity: 1.0;
}