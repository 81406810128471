.list {
  padding: 0;
}

.list :global(.MuiListItem-gutters) {
  padding: 9px 11px;
  box-shadow: var(--border_bottom_shadow);
}

.list :global(.MuiListItemText-root) {
  margin: 0;
}

.container {
  display: flex;
  justify-content: space-between;
}

.container a {
  width: 100%;
}

.actions {
  display: flex;
  align-items: center;
}

.name {
  color: var(--gray_text);
  font: var(--text_4);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 60vw;
}

.date {
  color: var(--gray_icon);
  display: flex;
  font: var(--text_5);
  margin-bottom: 8px;
}

.items {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.gain {
  font: var(--text_4);
  padding: 0 4px !important;
  width: auto;
  max-width: initial;
}
