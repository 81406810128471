.whiteBlock {
  background-color: white;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.watchlistInput {
  width: 350px;
  display: flex;
  margin-bottom: -2px;
}