.orderBy {
  width: 185px;
  margin-left: auto;
  margin-top: 4px;
}

.loading {
  max-height: 300px;
}

.chart {
  grid-row: 2 / 4;
  grid-column: 2;
  margin: -10px -10px 0 -10px;
}

.chart tspan {
  stroke-width: 0;
}

.searchAndOrder {
  height: 61px;
  max-height: 61px;
  min-height: 61px;
  padding-right: 24px;
  box-shadow: inset 0px -1px 0px var(--gray_border);
  display: flex;
  align-items: center;
}

.searchAndOrder.mobile {
  flex-wrap: wrap;
  height: max-content;
  max-height: max-content;
  padding: 0;
}

.searchAndOrder.mobile > div:first-child {
  border-bottom: 1px solid var(--gray_border);
  padding: 12px;
}

.searchAndOrder.mobile .search.search {
  width: 100%;
  margin-left: 0;
}

.searchAndOrder.mobile > div:nth-child(2) {
  margin-top: 0;
  padding: 12px;
}
.searchAndOrder.mobile > div:nth-child(2) label {
  transform: translate(26px, 8px) scale(0.75);
}

.searchAndOrder.mobile > div {
  width: 100%;
}

.search.search {
  width: 214px;
  margin-left: 10px;
}

.search fieldset {
  border: none;
}

.content {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
  padding: 24px;
  /*box-shadow: 0px 0px 0px rgb(63 63 68 / 0%), 0px 1px 2px rgb(63 63 68 / 15%);*/
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.content.mobile {
  display: flex;
  flex-direction: column;
}

.bars {
  grid-row: 2;
  grid-column: 1;
}

.header {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: var(--gray_icon);
}

.headerTicker {
  flex-basis: 100px;
  flex-grow: 1;
}

.headerWinLoss {
  flex-basis: 100px;
  flex-grow: 1;
  text-align: right;
}

.headerRewardRisk {
  flex-basis: 100px;
  flex-grow: 1;
  text-align: right;
  margin-left: 24px;
}

.chartHeader {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: var(--gray_icon);
}

.mobileChartHeader {
  margin: 20px 0 0 10px;
}