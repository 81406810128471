.orders {
  padding-left: 12px;
  padding-bottom: 24px;
}

.status {
  display: block;
}

.status,
.status svg {
  min-width: 18px;
  max-width: 18px;
  width: 18px;
  position: relative;
  top: 1px;
}

.open,
.close {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.83px;
  text-transform: uppercase;
  color: var(--gray_icon);
  padding: 12px 0;
}

.open {
  grid-column: 3;
}

.close {
  grid-column: 5;
}

.orderData {
  margin-left: 20px;
}

.toggleButtons {
  border-bottom: 1px solid var(--gray_border);
  padding: 12px 24px;
  margin-left: -4px;
  margin-right: -16px;
  margin-bottom: 6px;

  display: flex;
}

.toggleInterval {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;

  /* border: 1px solid red; */
}

.toggleInterval span {
  font: var(--text_4);
  color: var(--gray_label);
}

.clickedTicker {
  color: var(--blue);
  cursor: pointer;
}

.toggleIntervalOn {
  cursor: pointer;
  font: var(--text_4_m);
  color: var(--blue);
  margin-left: 4px;
}
.toggleIntervalOff {
  cursor: pointer;
  font: var(--text_4_m);
  color: var(--gray_label);
  margin-left: 1rem;
}
