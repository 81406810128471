.swiperWrapper :global(.tns-nav) {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.swiperWrapper :global(.tns-nav) > button {
  margin-left: 8px;
  margin-right: 8px;
  background-color: var(--gray_border);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  outline: none;
  border: none;
  padding: 0;
}

.swiperWrapper :global(button.tns-nav-active) {
  background-color: var(--gray_label);
}

.swiperWrapper {
  position: absolute;
  width: 100%;
}

.slide {
  background-color: var(--white);
  height: 417px;
  box-shadow: var(--block_shadow);
  border-radius: 4px;
  margin: 2px 22px 2px 2px;
  cursor: pointer;
}

.image {
  width: 100%;
  margin-top: -20px;
}

.info {
  padding: 0 16px;
}

.heading {
  padding-bottom: 10px;
  letter-spacing: -0.15px;
  font-size: 20px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: var(--gray_label);
  min-height: 60px;
  margin: 0 0 20px;
}

.createOrExploreButtons {
  display: flex;
  margin-top: -4px;
}

.exploreButton {
  padding: 8px 16px;
  color: var(--white);
  background-color: var(--blue) !important;
  box-shadow: var(--btn_shadow);
  border-radius: 4px;
  margin-right: 16px;
  letter-spacing: 0.1px;
  transition: 0.2s ease;
}

.exploreButton:hover {
  box-shadow: var(--btn_shadow), 0 0 0 100px rgb(0 0 0 / 10%) inset;
}

.createButton {
  padding: 8px 16px;
  color: var(--blue);
  letter-spacing: 0.1px;
}

.tradings {
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--gray_dim);
}

.trading {
  margin-right: 2px;
  min-width: 48px;
  min-height: 28px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-color: var(--white);
}

.activeTrading {
  border-color: var(--blue);
  color: var(--gray_label);
}
