.contentColor {
  color: var(--gray_label);
}

.cardContent {
  padding-bottom: 24px;
}

.cardActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 24px 16px;
}

.cardStats span:last-child {
  font-size: 14px;
}

.cardStats {
  display: flex;
}

.cardStats > *:not(:last-child) {
  margin-right: 12px;
  margin-left: 0;
}

.colorPlus {
  color: var(--green);
}

.colorMinus {
  color: var(--red);
}

.root {
  width: 100%;
}

.dashboardChart {
  /*border-bottom: 1px solid var(--gray_border);*/
  position: relative !important;
}

.dashboardChart :global(.tns-nav) {
  position: absolute;
  bottom: 4px;
  z-index: 999;
  width: 100%;
}

.dashboardChart :global(.tns-outer) {
  position: relative;
}


.dashboardImage {
  height: 164px;
}

.slide {
  background-color: var(--white);
  height: 188px;
  box-shadow: var(--block_shadow);
  /*border-radius: 4px;*/
  /*margin: 2px 22px 2px 2px;*/
  cursor: pointer;
  text-align: center;
}

.chartCover {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.tryAlgo {
  position: relative;
  right: 0;
  background-color: var(--blue) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  color: var(--white);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  transition: 0.2s ease !important;
}

.tryAlgo:hover {
  box-shadow: var(--btn_shadow), 0 0 0 100px rgb(0 0 0 / 10%) inset !important;
}

.buttons > div > button {
  box-shadow: none !important;
}

.viewAlgo {
  min-width: 100px;
}

.tryProgress {
  padding-right: 16px;
}

.algoDescription {
  composes: contentColor;
  min-height: 76px;
  max-height: 76px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.algoDescription img {
  display: none;
}

.algoName {
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 0;
  line-height: 20px;
  width: 100%;
  font-weight: bold;
}
.algoNameText {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 10px;
}

.chartDates {
  border-top: 1px solid var(--gray_border);;
  font-size: 12px;
  color: var(--gray_icon);
  top: 17px;
  position: relative;
  padding: 6px 12px 0 12px;
  display: flex;
  justify-content: space-between;
}
