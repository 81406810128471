.tag {
  height: 24px;
  margin: 4px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  max-width: calc(100% - 6px);
  background-color: var(--back_light);
  padding: 0 5px;
}

.tagLabel {
  font: var(--text_4);
  overflow: hidden;
  white-space: nowrap;
  padding-right: 6px;
  text-overflow: ellipsis;
  max-width: 60vw;
  color: var(--gray_text);
}

.tagLabel.blue {
  color: var(--blue);
}

.tagButton {
  padding: 0 0 2px;
}

.icon {
  margin-right: 5px;
  color: var(--blue);
}

.link {
  display: flex;
  align-items: center;
}
