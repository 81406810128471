.help {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.83px;
  color: var(--gray_icon);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 0;
}

.help:hover {
  background-color: initial;
}

.icon {
  margin-left: 5px;
}
