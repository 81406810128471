.copyClipboardWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.copyClipboardWrapper > pre {
  width: 100%;
}

.copyIcon {
  position: absolute;
  right: 0;
  padding: 6px;
  display: none;
  margin-right: 2px;
}

.md, .md p, .md pre {
  width: 100%;
}

.md hr {
  color: var(--gray_dim);
  border-width: 0.5px;
}

.md blockquote {
  border-left: 1px solid var(--gray_dim);
  padding-left: 8px;
  margin-left: 0;
  font-style: italic;
}

.md code, .md code span {
  font-family: 'Roboto Mono' !important;
  padding: 4px;
  /*TODO: move to vars?*/
  background: rgba(237, 241, 244, 0.8) !important;
}

.inputWrapper h1:not(.placeholder):before, .inputWrapper h2:not(.placeholder):before, .inputWrapper h3:not(.placeholder):before {
  display: none !important;
  content: "" !important;
}

.inputWrapper h1, .inputWrapper h2, .inputWrapper h3 {
  margin-top: 8px !important;
}

.readOnly h3 > span:first-child, .readOnly h2 > span:first-child, .readOnly h1 > span:first-child {
  display: none !important;
}

.inputWrapper {
  width: 100%;
  margin-top: 24px;
}

.readOnly {
  margin-top: 0;
}

.readOnly p:empty, .readOnly p br {
  display: none;
}


.mdEditor, .mdEditor > div {
  border-radius: 0 !important;
  padding-bottom: 0;
  border: 0 none !important;
  box-shadow: 0 0 0 0 transparent !important;
}

.mdView, .mdEditor {
  font-family: var(--main_font) !important;;
}

.mdView hr, .mdEditor hr {
  height: 1px !important;
  border: 0 none !important;
}

.mdView img {
  display: none !important;
}
