.field {
  border-radius: 4px;
  padding: 5px !important;
}

.green {
  color: var(--green);
  background-color: var(--green_dim);
}

.red {
  color: var(--red);
  background-color: var(--red_dim);
}

.grey {
  color: var(--gray_dim);
  background-color: var(--back_light);
}
