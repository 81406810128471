.root {
  position: absolute !important;
  z-index: 100 !important;
  height: calc(100vh - 64px);
}

.root :global(.MuiDialog-paperFullScreen) {
  box-shadow: none;
  overflow: hidden;
}

.root :global(.MuiBackdrop-root) {
  background-color: var(--white);
  opacity: 1 !important;
  visibility: visible !important;
}

.appBar {
  position: relative;
  box-shadow: none;
  background-color: inherit;
  border-bottom: 1px solid var(--gray_border);
  align-items: center;
  padding: 8px 16px !important;
  display: flex;
  flex-direction: row;
}

.exitButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: var(--gray_label);
  padding: 10px 16px;
  width: fit-content;
  margin-left: auto;
}

.exitSaveButton {
  color: var(--blue);
  margin-left: 0;
}

.fullScreenWrap {
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
}

.btnEnterFullScreenText {
  font: var(--text_4_up_m);
  margin-right: 10px;
  text-transform: uppercase;
}

.enterButton {
  padding: 13px 16px;
  color: var(--gray_icon);
  margin-right: 4px;
}

.dialogContent {
  display: flex;
  height: 100%;
  overflow: hidden;
  box-shadow: inset 0 -1px 0 var(--gray_border);
}

.editorContainer {
  position: relative;
  width: 100%;
}

.criteriaInputs {
  height: 100%;
}

.criteriaInputs div[class^="CriteriaBlock_container"] {
  flex-direction: column;
  height: 100%;
}

.criteriaInputs div[class^="CriteriaInput_container"] {
  width: 100%;
  height: 50%;
  border: none;
}

.criteriaInputs div[class^="CriteriaInput_container"]:not(:first-child) {
  border-top: 1px solid var(--gray_border);
  z-index: 5;
}

.criteriaInputs :global(.ace_editor) {
  height: 94% !important;
}

.criteriaInputs div[class^="CriteriaInput_error"] {
  display: none;
}

.parameters {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid var(--gray_border);
  border-left: 0;
  z-index: 10;
  background-color: var(--white);
}
.resizablePanel {
  min-width: 415px;
  max-width: 800px;
}

.collapsableListWrapper {
  /*max-width: 415px;*/
  width: 100%;
  min-width: 415px;
  border-left: 1px solid var(--gray_border);
}

.searchWrapper {
  padding: 16px;
}

.searchInput {
  width: 100%;
  height: 40px;
  outline: none;
  color: var(--black);
  font-size: 16px;
}

.searchInput > input::placeholder {
  color: var(--black);
  opacity: 0.3;
}

.clearSearch {
  margin-right: -10px;
}

.list {
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
  overflow: auto;
  border-top: 1px solid var(--gray_border);
}

.list :global(.MuiAccordion-root) {
  margin: 0;
  box-shadow: none;
  border-bottom: 1px solid var(--gray_border);
}

.list :global(.MuiAccordion-root:before) {
  background: none;
}

.heading {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.83px;
  text-transform: uppercase;
  color: var(--gray_icon);
}

.docItem {
  padding: 16px 16px 12px 32px;
  background-color: var(--white);
}

.docItem h1 {
  display: none;
}

.docItem h2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.83px;
  text-transform: uppercase;
  color: var(--gray_icon);
  margin-bottom: 8px;
  margin-top: 20px;
}

.docItem h2:first-of-type {
  margin-top: 0;
}

.docItem p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--gray_text);
  margin: 0;
  text-align: justify;
}

.docItem p:not(:first-child) {
  padding-top: 5px;
}

.docItem a {
  color: var(--blue);
  text-decoration: none;
  word-break: break-word;
}

.docItem ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.docItem :global(#lines + ul),
.docItem :global(#parameters + ul) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -8px;
}

.docItem :global(#lines + ul) li,
.docItem :global(#parameters + ul) li {
  margin-top: 8px;
  margin-right: 8px;
}

.docItem :global(#formula + ul) {
  padding: 4px 8px !important;
  background: rgba(237, 241, 244, 0.8) !important;
}

.docItem :global(#formula + ul) li {
  margin: 0;
  font: var(--text_code) !important;
}

.docItem :global(#formula + ul) li:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.docItem :global(#formula + ul) li:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.docItem :global(#formula) ~ p {
  margin-top: 8px;
}

.docItem :global(#example) ~ pre {
  padding: 0 !important;
}

.docItem :global(#example) ~ pre div pre,
.docItem :global(#example) ~ p div pre {
  padding: 8px !important;
  width: 100%;
}

.docItem :global(#example) ~ pre div,
.docItem :global(#example) ~ p div {
  display: flex;
}

.docItem :global(#example) ~ pre div:hover button,
.docItem :global(#example) ~ p div:hover button {
  display: block;
}

.docItem li {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--gray_text);
}

.docItem pre {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--gray_text);
  padding: 0 4px !important;
  margin: 0;
  background: rgba(237, 241, 244, 0.8) !important;
  border-radius: 2px;
}

.docItem p pre {
  width: auto;
}

.docItem * + pre:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 11px 16px 4px !important;
}

.docItem * + pre:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 4px 16px 11px !important;
}

.docItem code > * {
  font: var(--text_code) !important;
}

.groupSummary {
  min-height: 40px !important;
  padding-left: 16px !important;
  padding-right: 9px !important;
  border-bottom: 0 !important;
}

.groupSummary :global(.MuiAccordionSummary-content) {
  order: 0;
  margin: 0 !important;
}

.groupSummary :global(.MuiIconButton-root) {
  padding: 10px;
}

.accordionDetails {
  padding: 0;
}

.category {
  border-bottom: 0 !important;
}

.category:global(.Mui-expanded) {
  border-bottom: 1px solid var(--gray_border) !important;
  background: rgba(247, 249, 250, 0.4);
}

.category:global(.Mui-expanded):last-child {
  border-bottom: none !important;
}

.category:global(.Mui-expanded) + .category:global(.Mui-expanded) {
  margin-top: -1px;
}

.category:global(.Mui-expanded) + .category:global(.Mui-expanded) :global(.Mui-expanded) {
  border-top: 0;
}

.category :global(.MuiAccordionSummary-content) {
  order: 0;
  margin: 0 !important;
}

.category :global(.MuiAccordion-rounded) {
  border-bottom: none;
}

.categorySummary {
  font-size: 14px;
  line-height: 18px;
  color: var(--gray_text);
  padding: 6px 12px 6px 32px !important;
  cursor: pointer;
  min-height: auto !important;
}

.categorySummary:global(.Mui-expanded) {
  font-weight: bold;
  line-height: normal;
  margin: 0;
  box-shadow: 0 1px 0 0 var(--gray_border) inset, 0 -1px 0 0 var(--gray_border) inset;
  border-bottom: 0;
}

.categorySummary :global(.MuiAccordionSummary-expandIcon) {
  padding: 0;
  margin-right: 7px;
}

.categorySummary:not(:global(.Mui-expanded)) :global(.MuiAccordionSummary-expandIcon) {
  display: none;
}

.groupSummary:hover,
.categorySummary:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.initialLine {
  border-bottom: none;
}

.initialLine > div {
  margin: 2px;
}
