.item {
  padding: 0 16px;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--gray_text);
  min-height: 44px !important;
}

.label {
  padding-left: 10px;
  line-height: 19px;
}

.tooltip {
  background-color: red;
}
