.criteriaBlock {
  display: flex;
  width: 100%;
  box-shadow: 0 1px 0 1px rgba(63, 63, 68, 0.05), var(--block_shadow);
}

.criteriaBlock > div:nth-child(1) {
  border-left: 0 none;
  border-right: 0 none;
}

.criteriaBlock > div:nth-child(2) {
  border-right: 0 none;
}

.criteria {
  width: 50%;
}

.inputs_container {
  padding: 16px 24px 5px;
  width: 100%;
  background-color: var(--white);
  box-shadow: var(--block_shadow);
  overflow: hidden;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid var(--gray_border);
}

.marginTop5 {
  margin-top: 5px;
}

.stockInput {
  margin-top: 24px;
}

.nameInput p {
  position: absolute;
  bottom: -20px;
}
