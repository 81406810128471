.accordionContainer {
  border-top: 1px solid var(--gray_border);
  padding: 8px 16px;
}

.accordionHeader {
  font: var(--text_5_up_m);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--gray_icon);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
}

.accordionBody {
  margin-top: 6px;
  margin-left: 12px;
  margin-right: 12px;
}

.openButton {
  padding: 0;
  color: var(--gray_icon);
}
