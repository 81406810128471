.timePicker {
  width: 130px;
  position: relative;
}

.timeField > div > input {
  border: none;
}

.timePicker > label:not([data-shrink="true"]) {
  transform: translate(14px, 1px) scale(1);
  height: 38px;
  padding-top: 11px;
  color: var(--gray_icon) !important;
}

.timePicker > div {
  padding-right: 0;
}

.timePicker input {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

.timePicker label {
  white-space: nowrap;
  background-color: white;
  padding-right: 4px;
}

.timePicker > div > div {
  margin-left: 0;
}

.tooltip {
  white-space: pre-line;
}

.clockIcon {
  width: 20px;
}

.noRightRadius > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.timeInputRight > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.timeInputRight fieldset {
  margin-left: -1px;
}

.timeInputRight
  :not([class*="MuiOutlinedInput-root"]:hover, [class*="Mui-error"], [class*="Mui-focused"])
  fieldset {
  border-left: none;
}

.timePicker p {
  position: absolute;
  top: 39px;

  min-width: max-content;
}
