.phoneCountry {
  position: relative;
}

.phoneNumber {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.phoneNumber input,
.phoneNumber :global(.MuiInputLabel-formControl) {
  padding-left: 60px;
}

.phoneNumber :global(.MuiInputLabel-formControl.MuiFormLabel-filled) {
  padding-left: 0;
}

.input {
  margin-top: 8px;
  width: 100%;
}

.input input {
  border: 1px solid var(--gray_border);
  min-height: 40px;
  border-radius: 4px;
  padding: 0 0 0 64px;

  display: flex;
}

.input :global(.MuiFormHelperText-root) {
  position: absolute;
  margin-top: 13px;
  color: var(--red);
}

.input :global(.MuiInputLabel-root) {
  top: 8px;
  left: 8px;
  color: var(--gray_dim);
  background-color: var(--white);
}

.countryList {
  width: 50px;
  position: absolute;
  top: 0;
  margin-top: 34px;
  margin-left: 6px;
}

.countryList :global(.flag-select__btn),
.countryList :global(.flag-select__btn:active),
.countryList :global(.flag-select__btn:focus) {
  outline: none;
  border: none;
  padding: 0;
}

.countryList :global(.flag-select__btn) > span {
  margin: 1px 0 7px 1px;
}

.countryList :global(.flag-select__btn:after) {
  margin: 0 0 6px 3px;
}

.countryList :global(.flag-select) ul {
  max-height: 281px !important;
  width: 340px;
  left: -6px;
  margin-top: 10px;
  border: none;
  box-shadow: var(--popup_shadow);
}

.countryList :global(.flag-select) ul li {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 20px 3px 28px;
  margin: 0;
  max-height: 24px;
}

.countryList :global(.flag-select) ul li:hover {
  background-color: var(--back);
}

.countryList :global(.flag-select) ul li svg {
  top: 4px;
}

.countryList :global(.flag-select) ul li > span {
  height: 14px;
  font-size: 14px;
  margin: 0;
}

.countryList :global(.flag-select) {
  white-space: nowrap;
  padding-bottom: 0 !important;
}

.countryList :global(.flag-select) ul > div {
  padding: 8px;
}

.countryList :global(.flag-select) ul > div input {
  height: 40px;
  width: calc(100% - 8px) !important;
  margin: 4px 4px 0 4px !important;
  padding: 10px 10px 10px 14px !important;
  border: 1px solid var(--gray_border) !important;
  border-radius: 4px;
}

.countryList :global(.flag-select) ul > div input::placeholder {
  color: var(--gray_dim);
  font: var(--text_3);
}

.input :global(.MuiInputLabel-root)[data-shrink="true"] {
  z-index: 100;
  padding: 0 4px;
}

@media only screen and (max-height: 760px) {
  .countryList :global(.flag-select) ul {
    max-height: 220px !important;
  }
}

@media only screen and (max-height: 624px) {
  .countryList :global(.flag-select) ul {
    max-height: 180px !important;
  }
}

@media only screen and (max-width: 390px) {
  .countryList :global(.flag-select) ul {
    width: 324px !important;
  }
}

@media only screen and (max-width: 375px) {
  .countryList :global(.flag-select) ul {
    width: 311px !important;
  }
}

@media only screen and (max-width: 360px) {
  .countryList :global(.flag-select) ul {
    width: 296px !important;
  }
}

@media only screen and (max-width: 320px) {
  .countryList :global(.flag-select) ul {
    width: 256px !important;
  }
}
