.sliderWrapper {
  //height: 100px;
  max-width: 600px;
  width: 100%;
  padding-left: 4px;
  //display: inline-flex;
}

.minMaxNumber {
  width: 40px;
}

.valueLabel {
  top: -16px;
  left: calc(-50% - 8px);
}
.valueLabel > span > span {
  text-align: center;
}
.valueLabel > span {
  width: 48px;
  height: 24px;
  border-radius: 4px;
  transform: none;
}

.valueLabel > span > span {
  transform: none;
}

.thumb, .track, .mark {
  color: var(--blue);
}

.wording > span {
  padding: 6px 12px 16px;
  display: inline-flex;
  color: var(--gray_label);
}

.wording input {
  color: var(--blue);
}

.wording input:after {
  border-bottom: 2px solid var(--gray_label) !important;

}
.wording input {
  border-color: var(--gray_label);
}
.wording > span:last-child {
  padding-right: 0;
}

.wording > span > b {
  padding-right: 8px;
}

@media only screen and (max-device-width: 480px) {
  .wording > span > b {
    min-width: 54px;
    width: 54px;
  }

  .sliderWrapper {
    padding: 18px 0 0;
    max-width: 320px;
    width: 100%;
  }
}