.container {
  background-color: var(--white);
  border-bottom: 1px solid var(--gray_border);
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.formRow:not(:last-child) {
  border-bottom: 1px solid var(--gray_border);
  padding-bottom: 16px;
}

.formRow:not(:first-child) {
  padding-top: 16px;
}

.formItem {
  width: 190px
}

.riskInput.riskInput {
  margin-top: 0;
  margin-bottom: 0;
}

.riskInput > div {
  height: 40px;
}

.comboRight > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.comboLeft > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.comboLeft > div:not(:hover):not([class*="Mui-focused"]) fieldset {
  border-left: none;
}

.switch {
  flex-direction: row;
}

.formItem [class*="MuiFormControlLabel-label"] {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--gray_icon);
}

.switch [class*="Mui-checked"] + [class*="MuiSwitch-track"] {
  background-color: var(--blue);
}

.typeSelect {
  min-width: 80px;
  height: 40px;
  margin: 0;
}

.typeSelect [class*="MuiSelect-root"] {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 11px;
  line-height: 0;
}

.typeSelect [class*="MuiSelect-icon"] {
  right: 14px;
}

.svg {
  width: 18px;
  height: 18px;
}

.formContainer {
  margin: 24px;
}

.riskInputWrapper {
  display: flex;
}

.helpIcon {
  cursor: pointer;
}

.helpIcon svg {
  color: var(--blue) !important;
  fill: var(--gray_icon) !important;;
}