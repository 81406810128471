.leftPane {
    order: -1;
    width: 15em;
    min-height: 100%;
    border-right: 1px solid var(--gray_border);
}

.leftPane > div {
    border-right: 1px solid var(--gray_border) !important;
    border-radius: 0;
}

.leftPane > div > div {
    padding: 0 24px;
}