.wrapper {
  display: flex;
  justify-content: flex-end;
}

.icon {
  color: var(--gray_icon);
}

.menu :global(.MuiPaper-rounded) {
  border-radius: 4px !important;
  margin-left: 40px;
  margin-top: 0;
  box-shadow: var(--popup_shadow);
}

.menu :global(.MuiPaper-rounded) > ul {
  padding-top: 0;
  padding-bottom: 0;
}

.button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
  min-width: 40px;
}
