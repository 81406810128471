.popupTour {
  text-align: center;

  h2 {
    color: var(--gray_text);
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: var(--gray_label);
  }

  &__btns {
    > button:first-child {
      margin-right: 10px;
    }

    > button:last-child {
      margin-right: 10px;
    }
  }
}

.close{
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
}

.exploreButton{
  margin: 0;
}