.container {
  display: flex;
}

.container.mobile {
  flex-direction: column;
}

.description {
  background-color: var(--white);
  padding: 12px 48px 8px 16px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid var(--gray_border);

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--gray_icon);
  min-height: 36px;
}
