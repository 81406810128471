.name {
  font: var(--text_4);
  color: var(--gray_text);
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  margin-left: 16px;
  margin-top: 4px;
  max-width: 500px;
}

.algosDesc {
  font-size: 14px;
  line-height: 22.02px;
  color: var(--gray_label);
}

.algosDesc a {
  color: var(--blue);
}
