.container {
  display: flex;
  position: relative;
  min-height: 40px;
  background-color: var(--white);
  box-shadow: var(--block_shadow);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.limitBlock {
  display: flex;
  align-items: center;
}

.limitInput > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.limitInput {
  max-width: 60px;
  margin-left: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.limitInput_large {
  max-width: 120px;
}

.limitInput > [class*="MuiFormHelperText"] {
  bottom: -21px;
  width: max-content;
}

.limitInput label {
  white-space: nowrap;
  background-color: white;
  padding-right: 6px;
}

.fullContainer {
  display: flex;
  height: 88px;
  align-items: center;
  flex-wrap: wrap;
}

.shortContainer {
  width: 100%;
}

.container > .shortContainer {
  position: absolute;
}

.toggleButtons {
  margin-left: 16px;
}

.select {
  margin-left: 16px;
  min-width: 140px;
}

.limitSelect > div {
  min-width: 140px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.limitSelect fieldset legend{
  width: 0;
}

.limitSelect fieldset {
  margin-left: 1px;
}

.limitSelect :not([class*="MuiOutlinedInput-root"]:hover, [class*="Mui-error"], [class*="Mui-focused"]) fieldset {
  border-left: none;
}

.dropDown {
  padding-right: 8px;
}

@media only screen and (max-device-width: 1152px) {
  .fullContainer {
    margin-bottom: 20px;
  }
  .fullContainer .select {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media only screen and (max-device-width: 954px) {
  .fullContainer {
    min-height: 160px;
  }
  .fullContainer .select:nth-child(2) {
    margin-right: 40px;
  }
}
