.card {
  background: var(--white);
  box-shadow: var(--block_shadow);
  border-radius: 4px;
}

.cardHeader {
  font: var(--text_5_up_m);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--gray_label);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
