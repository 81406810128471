.shortInfo {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
}

.intervalContainer {
  display: flex;
  line-height: 12px;
  margin-left: 16px;
}

.intervalLabel {
  color: var(--gray_icon);
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.interval {
  margin-left: 4px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 2px;
  padding: 4px;
  border-radius: 2px;
  background-color: var(--back_light);
}

.name {
  margin-left: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.nameInput p {
  position: absolute;
  bottom: -20px;
}

.chevron {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.marginTop {
  margin-top: 24px;
}

.description {
  margin-top: 20px;
}

.InitialForm {
  padding: 16px 56px 24px 24px;
}

.shortContainer {
  position: relative;
  height: 36px;
}

.block {
  box-shadow: var(--block_shadow);
  background-color: var(--white);
  padding: 0 20px;
  border-radius: 4px;
}

.collapse {
  padding-bottom: 20px;
}

.container {
  background-color: var(--white);
  border-bottom: 1px solid var(--gray_border);
}

.nameShort {
  display: inline-block;
  width: 50vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionButton {
  margin-left: auto;
}

.initialLineWrapper div {
  flex-wrap: nowrap;
}
