.popupTour {
  text-align: center;

  h2 {
    color: var(--gray_text);
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: var(--gray_label);
  }

  &__btns {
    > button:first-child {
      margin-right: 10px;
    }

    > button:last-child {
      margin-right: 0;
    }
  }
}

.btnGrey {
  color: var(--gray_label);
  padding: 8px 16px;
}

.reactour {
  max-width: 430px !important;
}

.finish{
  margin: 0;
}

@media only screen and (max-device-width: 480px) {
  div.reactour {
    max-width: 300px !important;
  }
  .tour_welcome {
    max-width: 100%;
  }
}