.appBarMargin {
  top: 46px !important;
}

.appBar button[title="Close menu"] {
  visibility: hidden;
}

.banner {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  font: var(--text_4_m);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.blackContainer {
  background-color: var(--black);
  justify-content: center;
  width: 100vw;
}

.blueContainer {
  background-color: var(--blue_dim);
  justify-content: center;
  width: 100vw;
}

.blackMessage {
  color: var(--white);
  padding: 0 6px;
}

.blueMessage {
  color: var(--gray_text);
  padding: 0 6px;
}

.link {
  color: var(--blue);
  text-transform: none;
  padding: 0;
}

.closeBanner {
  position: absolute;
  right: 16px;
}

.redContainer {
  background-color: var(--red_dim);
  justify-content: center;
  width: 100vw;
}

.redMessage {
  color: var(--red);
  padding: 0 6px;
}

.warningAction {
  text-transform: uppercase;
  color: var(--gray_label);
  font: var(--text_4_up_m);
  padding: 0;
}

.logo {
  margin-right: 32px;
}

@media only screen and (max-device-width: 480px) {
  .logo {
    margin-right: 0;
    display: flex;
  }
}
